import "./index.scss";
import İmg1 from "../../../assets/images/Ati STP.jpeg";
import İmg2 from "../../../assets/images/Primary WMS.jpeg";
import İmg3 from "../../../assets/images/Professional WMS.jpeg";
import İmg4 from "../../../assets/images/Ultimate WMS.jpeg";
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

const Carusel =() =>{

    const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

    return (
        <div className="carusel-main-block">
            <div className="container">
      <Carousel activeIndex={index} onSelect={handleSelect} className="carousel-dark">
      
      <Carousel.Item >
      <a href="/urunlerimiz/stok-takip-programı-ati-stp" >
        <img
          className="d-block w-100 koyu"
          height={500}
          src={İmg1}
          alt="Ati STP"
        />
        <Carousel.Caption className="text-zonem">
          
          <h3 className="header-style">Stok Takip Programı: Ati STP</h3>
          <p className="p-style">Basit arayüzü ile ürünlerinizin stok ve satışlarını<br />dilediğiniz yerden kolayca takip edin.</p>
        </Carousel.Caption>
        </a>
      </Carousel.Item>
      <Carousel.Item>
      <a href="/urunlerimiz/primary-ati-wms" >
        <img
          className="d-block w-100 koyu"
          src={İmg2}
          height={500}
          alt="Primary Ati WMS"
        />

        <Carousel.Caption className="text-zonem">
          
          <h3 className="header-style">Primary Ati WMS</h3>
          <p className="p-style">Küçük-Orta Büyüklükte İşletmelerde<br />Depo Yönetimi İçin İdeal Çözüm</p>
        </Carousel.Caption>
        </a>
      </Carousel.Item>
      <Carousel.Item>
      <a href="/urunlerimiz/professional-ati-wms" >
        <img
          className="d-block w-100 koyu"
          src={İmg3}
          height={500}
          alt="Professional Ati WMS"
        />

        <Carousel.Caption className="text-zonem">
        
          <h3 className="header-style">Professional Ati WMS</h3>
          <p className="p-style">
          Orta-Büyük İşletmelerde<br />Depo Yönetimi İçin Profesyonel Yaklaşım
          </p>
        </Carousel.Caption>
        </a>
      </Carousel.Item>
      <Carousel.Item>
      <a href="/urunlerimiz/ultimate-ati-wms" >
        <img
          className="d-block w-100 koyu"
          src={İmg4}
          height={500}
          alt="Ultimate Ati WMS"
        />

        <Carousel.Caption className="text-zonem">
    
          <h3 className="header-style">Ultimate Ati WMS</h3>
          <p className="p-style">
          Büyük İşletmeler İçin <br />Depo Yönetimi & Entegrasyon Seçenekleri
          </p>
        </Carousel.Caption>
        </a>
      </Carousel.Item>
      
    </Carousel>
    </div>
        </div>
    )

}


export default Carusel;