import "./index.scss";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ImgProje1 from "../../../assets/images/projeyonetimi1.jpeg";
import ImgProje2 from "../../../assets/images/projeyonetimi2.jpeg";
import ImgProje3 from "../../../assets/images/projeyonetimi3.jpeg";


const ProjeYonetim = () =>{

return(

    <>
    <Card className="entegrasyonlar-card">
          <div className="container cozumler-card-container">
            <Card.Body  className="cozumler-card-body">
             <Card.Title className="cozumler-card-title">
             Proje Yönetim Hizmetleri<br /><br />
            </Card.Title>
             <Card.Text className="cozumler-card-text">
             Başarılı projeler, etkili yönetimle başlar. İşte bu noktada, sizin için en iyi sonuçları 
             elde etmenize yardımcı olmak için buradayız. Proje yönetimi, herhangi bir organizasyonun 
             büyümesi ve başarısı için kritik bir unsurdur. Biz, karmaşık projelerinizi başarıyla 
             planlamaktan, uygulamaktan ve sonuçlandırmaktan sorumlu profesyonel bir ekip olarak 
             işinizin yanındayız.
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
    <div className="container optimizeDepo projeYonetimi">
        <CardGroup className="block-display">
        <Card className="optimizeDepo-text-container">
        <Card.Body>
        <Card.Title className="optimizeDepo-card-title"></Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        Proje yönetimi, bir organizasyonun veya işletmenin hedeflerini gerçekleştirmek için stratejik 
        planlamayı, kaynak yönetimini, risk yönetimini ve işbirliğini içeren bir süreçtir. İyi bir proje 
        yönetimi, başarılı sonuçlar elde etmek için gereklidir.
        <br/><br/>Proje yönetimi hizmetleri, organizasyonlar ve işletmeler için birçok fayda sağlar. 
        İyi bir proje yönetimi, zaman ve bütçe sınırlarına uygun bir şekilde projelerin tamamlanmasına 
        yardımcı olur. Proje yönetimi hizmetleri, projelerin zamanında ve bütçeye uygun bir şekilde 
        tamamlanmasını sağlar ve işletmelerin müşteri memnuniyetini artırmasına yardımcı olur.
        </Card.Text>
      </Card.Body>
    </Card>
    <Card className="optimizeDepo-img-container">
          <Card.Img  src={ImgProje1} />
        </Card>
    </CardGroup>
        </div>
        <div className="container optimizeDepo">
        <CardGroup className="block-display">
        <Card className="optimizeDepo-img-container">
          <Card.Img  src={ImgProje2} />
        </Card>
        <Card className="optimizeDepo-text-container">
        <Card.Body>
        <Card.Title className="optimizeDepo-card-title"></Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        Proje yönetimi hizmetleri ayrıca işletmelerin riskleri yönetmesine yardımcı olur. Proje yönetimi 
        sürecinde risk yönetimi, olası riskleri tanımlamak ve önlemek için stratejik bir yaklaşımı içerir. 
        Proje yönetimi hizmetleri, organizasyonların riskleri azaltmasına ve projelerinin başarısını 
        artırmasına yardımcı olur.
        <br/><br/>
        Ati Software & Automation olarak proje yönetimi bakış açımızı hem üstlendiğimiz projelerde 
        uyguluyoruz, hem de bize danışmanız durumunda proje yönetimi süreçlerinizde destek olmak için 
        size hizmet olarak sunuyoruz. Bu hizmet, proje yönetimi planlamasını, uygulanmasını, kontrolünü 
        ve sonlandırılmasını kapsamaktadır.
        </Card.Text>
      </Card.Body>
    </Card>
    </CardGroup>
        </div>
        <div className="container optimizeDepo">
        <CardGroup className="block-display">
        <Card className="optimizeDepo-text-container">
        <Card.Body>
        <Card.Title className="optimizeDepo-card-title"><br/><br/>Proje Deneyimimiz</Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        <br/>Firmamız, bugüne kadar 6 akıllı depolama projesinde görev almıştır. Tüm projelerimizde, 
        müşterilerimize özel çözümler sunarak, işlerini daha verimli hale getirmelerini sağladık. 
        Her projede, ekibimiz sorumluluk sahibi olarak, proje yönetimi, kurulum, entegrasyon ve eğitim 
        süreçlerini yürüttü.
        </Card.Text>
      </Card.Body>
    </Card>
    <Card className="optimizeDepo-img-container">
          <Card.Img  src={ImgProje3} />
        </Card>
    </CardGroup>
        </div>
    </>
);

}

export default ProjeYonetim;