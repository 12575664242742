import "./index.scss";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CardGroup from 'react-bootstrap/CardGroup';
import ImgEntegre from "../../../assets/images/entegre.jpeg";

const EntegreDepo = () => {
    return (
        <>
        <div className="container entegreDepo">
        <CardGroup className="block-display">
        <Card className="entegreDepo-img-container">
          <Card.Img  src={ImgEntegre} />
        </Card>
        <Card className="entegreDepo-text-container">
        <Card.Body>
        <Card.Title className="entegreDepo-card-title">Entegre Bir Depo Hayal Değil!</Card.Title>
        <Card.Text className="entegreDepo-card-text">
        Günümüzde işçilik maliyetlerini ve kaliteyi kontrol edebilmek için Depo Yönetimi Sistemleri (WMS çözümleri) kullanmak artık zorunlu hale gelmiştir. ERP sisteminiz varsa bu ERP sistem ile entegre çalışan Ati WMS çözümleri, ERP sisteminiz olmaması durumunda da tüm özellikleri ile depo süreçlerinizi baştan uca kontrol edebilmenize olanak sağlar. Bütün avantajlarına ek olarak Ati WMS çözümleri;
          <ul>
<li>Satın almadan etiketlemeye kadar tüm depo süreçlerini otomatize ediyor.</li>

<li>En yeni barkod ve RFID gibi teknolojilerle uyum şekilde çalışarak verimliliği artırıyor.</li>

<li>El terminalleriyle entegre olan sistem, depolama işlemlerini pratik hale getiriyor.</li>

<li>İşletmelerin hedeflerine daha iyi ulaşabilmesi için raporlama araçları sunuyor.</li>

<li>Etiket, barkod ve raporların yazdırılması işlemlerini otomatize ediyor.</li>

<li>El terminalleri ile sürekli haberleşerek gerekli güncellemeleri sisteme hızla aktarıyor.</li>
</ul>
        </Card.Text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <a href="/entegrasyonlar" ><Button size="lg" className="button-style-entegreDepo-card">Detaylı Bilgi</Button></a>
        </div>
      </Card.Body>
    </Card>
    </CardGroup>
        </div>
        </>
    );
};



export default EntegreDepo;