import "./index.scss";
import React, { useState, useEffect } from 'react';

const Header1 = ()=> {
    const [show, setShow] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
  
    const controlHeader1 = () => {
      
      if (typeof window !== 'undefined') { 
        
        if (window.scrollY < lastScrollY) { // if scroll down hide the navbar
          setShow(false); 
        } else { // if scroll up show the navbar
          setShow(true);  
        }
  
        // remember current page location to use in the next move
        setLastScrollY(window.scrollY); 
      }
    };
  
    useEffect(() => {
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', controlHeader1);
  
        // cleanup function
        return () => {
          window.removeEventListener('scroll', controlHeader1);
        };
      }
    }, [lastScrollY]);

    
    return(
        
      <div className={`header11 ${show && 'hidden-header11'}`}>

        <div className="container-fluid header1 align-items-start" height="4.5rem">
            <div className="container-fluid d-flex">
                <div className="col-8 align-items-start">
                    <p className="px-2  p1 text-zone">
                        <i class="bi bi-telephone text-zone"></i>
                        <small className="px-2 text-zone border-color">0552 519 30 42</small>
                        <i class="bi bi-envelope-at px-2 text-zone"></i>
                        <small className="px-2 text-zone">bilgi@atiotomasyon.net</small>
                    </p>
                </div>
                <div className="col-2"></div>
                <div className="col-2 text-end">
                    <p className="p1">
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?viewas=100000686899395&id=100091208243162" ><i class="bi bi-facebook px-3 text-zone img-font-size"></i></a>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/ati-otomasyon/?viewAsMember=true" ><i class="bi bi-linkedin px-2 text-zone img-font-size"></i></a>
                   </p>
                </div>
            </div>
        </div>
        
      </div>
     
    );
     
};
  
export default Header1;