import "./index.scss";



const KVKKAydinlatma = () => {

    return(
        <>
        <div className="container aydinlatma-main">
            <h1 className="aydinlatma-baslik">
            6698 Sayılı KVKK Uyarınca Aydınlatma Metni
            </h1>
            <p className="aydinlatma-text">
            Ati OTOMASYON KİMYA ARGE SAN TİC LTD ŞTİ kişisel verilerinizin hukuka uygun olarak 
            toplanması, saklanması ve paylaşılmasını sağlamak ve gizliliğinizi korumak amacıyla 
            mümkün olan en üst seviyede güvenlik tedbirlerini almaktadır.
            </p>
            <br />
            <p className="aydinlatma-text">
            Amacımız; 6698 sayılı “Kişisel Verilerin Korunması Kanunu’nun 10. maddesi gereğince ve 
            sizlerin memnuniyeti doğrultusunda hangi kişisel verilerinizin firmamız tarafından 
            toplandığı, verilerinizin alınma şekilleri, işlenme amaçları, paylaşılan kişiler, hukuki 
            nedenleri ve haklarınız konularında sizi en şeffaf şekilde bilgilendirmektir.
            </p>
            <br />
            <h3 className="aydinlatma-baslik">
            Hangi Konularda Aydınlatma Yapıyoruz?
            </h3>
            <ol className="aydinlatma-text">
                <li>Hangi verilerinizin toplandığı</li>
                <li>Verinizin kimlerle paylaşıldığı</li>
                <li>Verilerinizin hangi amaçla işlendiği</li>
                <li>Verilerinizin toplanmasının hukuki sebebi</li>
                <li>Verinizin imhası</li>
                <li>KVKK kapsamında sahip olduğunuz haklar</li>
            </ol>
            <h3 className="aydinlatma-baslik">
            Hangi verileriniz tarafımızdan toplanıyor?
            </h3>
            <p className="aydinlatma-text">
            Çerezler vasıtasıyla tarayıcı bilgileriniz, IP adresiniz, web site içi erişim log 
            kayıtlarınız ile www.atiotomasyon.net adresinde yer alan iletişim formunu doldurmanız 
            halinde adınız, soyadınız, e-posta adresiniz, telefon numaranız ve Şirketimize iletmek 
            istediğiniz mesajınız firmamız tarafından işlenmekte ve kayıt altında tutulmaktadır.
            </p>
            <br />
            <h3 className="aydinlatma-baslik">
            Kişisel Verilerinizin Paylaşılması Nedenleri Nelerdir?
            </h3>
            <p className="aydinlatma-text">
            Yasal yükümlülükler ya da KVKK’nın 8/2 ile 9/2’nci maddesi şartlarının varlığı halinde 
            kişisel verileriniz; hukuka ve dürüstlük kuralına uygun olarak doğru ve güncel bir şekilde 
            belirli, açık ve meşru amaçlar dahilinde sınırlı ve ölçülü olmak kaydıyla, olası bir hukuki 
            ihtilaf, soruşturma, adli ya da idari denetim ve sair hallerde hukuki savunma hakkımızın 
            kullanılması, resmi ya da yasal diğer bilgi ve belge taleplerine yanıt verme gibi amaçlar 
            doğrultusunda şirket avukatımız, bilgi talep eden adli ve idari merciler ile Şirketimizin 
            açıklanan amaçları gerçekleştirebilmek amacıyla hizmet alacağı üçüncü taraf kişi veya 
            kişilerle veya yasal bir zorunluluk gereği bu verileri talep etmeye yetkili olan kamu 
            kurum veya kuruluşları ile paylaşılmaktadır.
            </p>
            <br />
            <h3 className="aydinlatma-baslik">
            Kişisel Verilerinizin Toplanma ve İşlenme Yöntemi Nedir?
            </h3>
            <p className="aydinlatma-text">
            Kişisel verilerinizi; www.atiotomasyon.net sitesine ilk giriş yaptığınız esnada çerezler 
            (cookies) vasıtasıyla tarayıcı bilgileriniz, IP adresini, web site içi erişim log kayıtlarınız 
            otomatik olarak; iletişim formunu doldurmanız suretiyle TOPLUYOR; dijital olarak bilişim 
            sistemine kaydetmek, fiziki sunucu ve bulut sunucu üzerinde saklamak ile işin niteliğine ve 
            talep edilen/işlenmesi gereken kişisel verilerin niteliğine göre otomatik ya da otomatik 
            olmayan yollarla veritabanı oluşturmak, liste yapmak, belge doldurmak, dilekçe yazmak, 
            form doldurmak vb. suretiyle işliyoruz.
            </p>
            <br />
            <h3 className="aydinlatma-baslik">
            Kişisel Verilerinizin İşlenme Amaçları Nedir?
            </h3>
            <p className="aydinlatma-text">
            Kişisel verileriniz, pazarlama analiz çalışmalarının yürütülmesi, hukuk işlerinin takibi 
            ve yürütülmesi, faaliyetlerin mevzuata uygun yürütülmesi, müşteri ilişkileri yönetimi 
            süreçlerinin yürütülmesi, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi, bilgi güvenliği 
            süreçlerinin yürütülmesi, veri sorumlusu operasyonlarının güvenliğinin temini, erişim 
            yetkilerinin yürütülmesi ve iletişim faaliyetlerinin yürütülmesi amaçlarıyla işlenmektedir.
            </p>
            <br />
            <h3 className="aydinlatma-baslik">
            Kişisel Veri İşletmenin Hukuki Sebebi Nedir?
            </h3>
            <ul className="aydinlatma-text">
                <li>Şirketimizin faaliyet alanıyla ilgili yasalarından doğan hukuki yükümlülüğümüzü yerine 
                    getirebilmemiz için veri işlemenin zorunlu olması
                </li>
                <li>İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Şirketimizin 
                    meşru menfaatleri için veri işlenmesinin zorunlu olması
                </li>
                <li>Mevzuatlarda açıkça öngörülme</li>
                <li>Hakkın tesisi, korunması ve kullanılması için veri işlemenin zorunlu olması.</li>
            </ul>
            <h3 className="aydinlatma-baslik">
            KVKK Kapsamında Sahip Olduğunuz Haklarınız
            </h3>
            <p className="aydinlatma-text">
            Şirketimize başvurarak kişisel verilerinizin;
            </p>
            <br />
            <ol className="aydinlatma-text">
                <li>İşlenip işlenmediğini öğrenme,</li>
                <li>İşlenmişse bilgi talep etme,</li>
                <li>İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                <li>Aktarılıp aktarılmadığını ve aktarıldıysa aktarıldığı 3. kişileri bilme,</li>
                <li>Eksik / yanlış işlenmişse düzeltilmesini isteme,</li>
                <li>KVKK’nın 7′ nci maddesinde öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme,</li>
                <li>Aktarıldığı üçüncü kişilere yukarıda sayılan (ç) ve (d) bentleri uyarınca yapılan işlemlerin bildirilmesini isteme,</li>
                <li>Münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</li>
                <li>Kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme hakkına sahipsiniz.</li>
            </ol>
            <h3 className="aydinlatma-baslik">
            Kişisel Verilerinizin Korunmasına İlişkin Önlemler
            </h3>
            <p className="aydinlatma-text">
            Kişisel verilerin korunması, şirketimiz için önemli bir konudur. Şirketimiz, kişisel verilere yetkisiz erişim veya bu bilgilerin 
            kaybı, hatalı kullanımı, ifşa edilmesi, değiştirilmesi veya imha edilmesine karşı korumak için gerekli 
            teknik ve idari tedbirleri almaktadır. Şirketimiz, kişisel verilerinizi gizli tutmayı, 
            gizliliğin sağlanması ve güvenliği için gerekli teknik ve idari her türlü tedbiri almayı ve 
            gerekli özeni göstermeyi taahhüt etmektedir.
            </p>
            <br />
            <p className="aydinlatma-text">
            Şirketimizin gerekli bilgi güvenliği önlemlerini almasına karşın, web sitesine ve sisteme 
            yapılan saldırılar sonucunda kişisel verilerin zarar görmesi veya üçüncü kişilerin eline 
            geçmesi durumunda, Şirketimiz bu durumu derhal sizlere ve Kişisel Verileri Koruma Kurulu’na 
            bildirir.
            </p>
            <br />
            <h3 className="aydinlatma-baslik">
            Kişisel Verilerinizin Doğru ve Güncel Olarak Muhafaza Edilmesi
            </h3>
            <p className="aydinlatma-text">
            Kişisel verilerini şirketimizle paylaşanlar, bu bilgilerin doğru olmasının ve güncel 
            bir şekilde muhafaza edilmesinin, hem 6698 Sayılı Kişisel Verilerin Korunması Kanunu 
            anlamında kişisel verileri üzerinde sahip oldukları haklar hem de ilgili diğer mevzuat 
            açısından önemli olduğunu bildiklerini ve yanlış bilgi verilmesinde doğacak sorumlulukların 
            tamamen kendilerine ait olacağını, kabul ve beyan etmişlerdir.
            </p>
            <br />
            <p className="aydinlatma-text">
            Paylaşmış olduğunuz kişisel verileriniz ile ilgili değişiklik ve/veya güncellemeleri 
            aşağıda belirtilen mail adresine veya şirketimizin adresine fiziki posta yoluyla gerekli 
            bildirimleri yapabilirsiniz.
            </p>
            <br />
            <h3 className="aydinlatma-baslik">
            Kişisel Verilerinizin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi
            </h3>
            <p className="aydinlatma-text">
            İşbu Bilgilendirme metninde belirtilen amaçlarla işlenmiş olan kişisel verileriniz; 
            KVKK md. 7/f.1’e göre işlenmesi gerektiren amaç ortadan kalktığında ve varsa Kanunların 
            belirlediği süreler geçince; Şirketimizin Kişisel Veri Saklama ve İmha Politikası 
            doğrultusunda re’sen veya talebiniz üzerine tarafımızca silinecek, yok edilecek veya 
            anonimleştirilerek kullanılmaya devam edilecektir.
            </p>
            <br />
            <h3 className="aydinlatma-baslik">
            Veri Sorumlusunun Kimliği
            </h3>
            <h3 className="aydinlatma-baslik">
            Ati OTOMASYON KİMYA ARGE SAN TİC LTD ŞTİ
            </h3>
            <p className="aydinlatma-text">
            Adres: Fevzi Çakmak Mah. 10537. Sok No:17 Karatay KONYA
            </p>
            <br />
            <p className="aydinlatma-text">
            Email: kvkk@atiotomasyon.net
            </p>
            <br />
        </div>
        </>
    );
}

export default KVKKAydinlatma;