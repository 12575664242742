import "./index.scss";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ImgIletisim from "../../assets/images/iletisim.jpeg";

const Iletisim = () => {

    return(

       <>
       <div className="container entegreDepo iletisim-first-part">
        <CardGroup  className="block-display">
        <Card className="entegreDepo-img-container">
          <Card.Img  src={ImgIletisim} />
        </Card>
        <Card className="entegreDepo-text-container">
        <Card.Body>
        <Card.Title className="entegreDepo-card-title"><br/><br/>Bizimle iletişime geçin.</Card.Title>
        <Card.Text className="entegreDepo-card-text">
            <br/><br/>
        Karmaşık süreçleriniz mi var? Bize ulaşın, basitleştirelim.<br/><br/>
        Talep ve önerileriniz için lütfen bizimle iletişime geçin. Sizden gelen dönüşler 
        bizi mutlu edecektir.
        </Card.Text>

      </Card.Body>
    </Card>
    </CardGroup>
        </div>
        <div className="container iletisim">
        <iframe title="adres" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3147.8758611904!2d32.528352274852345!3d37.909964204362694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d08fddef67ce65%3A0x1ca0e752da7d1b34!2sFevzi%C3%A7akmak%2C%2010537.%20Sk.%20No%3A17%2C%2042050%20Horozluhan%20Osb%2FKaratay%2FKonya!5e0!3m2!1str!2str!4v1695908188174!5m2!1str!2str" width={"1000"} height={"450"} allowfullscreen="yes" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
       </> 
    );
}

export default Iletisim;