import "./index.scss";
import Card from 'react-bootstrap/Card';
import image1 from "../../../assets/images/gnlblg1.jpeg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochip, faRectangleList, faLayerGroup, faTruckRampBox, faHandsHoldingChild, faTruckFast} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Img2 from "../../../assets/images/Primary WMS.jpeg";
import Img3 from "../../../assets/images/Professional WMS.jpeg";
import Img4 from "../../../assets/images/Ultimate WMS.jpeg";


const StokTakipProgramiAtiSTP =()=>{
    return(
        <>
        <div className="ati-stp-main-container">
        <Card className="bg-dark">
        <Card.Img src={image1} alt="Card image" className="card-image gnlblg1"/>
        <Card.ImgOverlay>
        <Card.Title className="card-title-gnlblg">Ati STP<br /><br />
        Basit, Hızlı, Kusursuz...
        </Card.Title>
        <Card.Text className="card-text-gnlblg">
        Stok Takip Programımız Ati STP esnek ve kolay kullanım amaçlı tasarlanmış bir uygulamadır. 
        Ayrı bir veri tabanına sahiptir.
         <br /><br />Günümüzde neredeyse tüm sektörler için işletmelerin en temel ihtiyaçlarından biri 
         depo süreçlerini güvenle yönetecekleri bir programdır. Start-up aşamasından global bir firma 
         yolunda tüm depo yönetim ihtiyaçlarınızı herhangi bir yeniden kurulum gerekmeksizin 
         Ati Software & Automation olarak sizlere sunuyoruz.
        </Card.Text>
        </Card.ImgOverlay>
        </Card>
        <Card className="farkımız-card">
          <div className="container farkımız-card-container">
            <Card.Body  className="farkımız-card-body">
             <Card.Title className="farkımız-card-title">Ati STP<br /><br />TEMEL ÖZELLİKLER</Card.Title>
             <Card.Text className="farkımız-card-text">
             Ati STP, işletmenizin depo yönetimini ve stok takibini optimize etmek için tasarlanmış 
             modüler altyapılı güçlü bir yazılımdır. İşletmenizin ihtiyaçları doğrultusunda modüller de 
             ekleyerek deponuz için özelleştirilmiş kabiliyeti yüksek bir yazılım da elde edebilirsiniz. <br /> 
             <br />İşte Ati STP'nin sağladığı temel özellikler: 
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        <div className="farkımız-cards-container">
        <div className="container card-group position-up">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faHandsHoldingChild} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
             Kullanıcı Dostu
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Kullanıcı dostu basit bir arayüz sayesinde işlemlerinizi hatasız ve hızlı yönetmenizi sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
             Teknoloji
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          ​Ati STP maksimum verimlilik için son teknoloji kullanılarak geliştirilmiştir.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container ">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faLayerGroup} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Modüler Altyapı</Card.Title>
          <Card.Text className="farkımız-card-text">
          Sizin için geliştirilmiş Modüller ile size en uygun Ati STP programını birlikte oluşturalım.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up">
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckRampBox} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Mal Kabul</Card.Title>
          <Card.Text className="farkımız-card-text">
          Deponuza giren tüm ürünlerin gerekli bilgileri girerek depoya kabulünü sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card  className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faRectangleList} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Sipariş İşlemleri</Card.Title>
          <Card.Text className="farkımız-card-text">
          Ati STP ile siparişin depodan transferine kadar tüm sürecini rahatça izleyip yönetebilirsiniz.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckFast} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Sevk Yönetimi</Card.Title>
          <Card.Text className="farkımız-card-text">
          Ürünlerinizin sevk aşamalarını ve son kontrollerini rahat bir şekilde yönetin.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
        </div>
        <Card className="farkımız-card">
          <div className="container farkımız-card-container">
            <Card.Body  className="farkımız-card-body">
             <Card.Title className="farkımız-card-title">Ati STP'niz İçin En Uygun <br /><br />Modülleri Belirleyin.</Card.Title>
             <Card.Text className="farkımız-card-text">
             Ati Stok takip Programını modüllerle genişletmek, işletmenizin özel ihtiyaçlarını karşılamak
              için harika bir yoldur. Bu modüller, depo yönetimi süreçlerini iyileştirmenizi ve daha
               verimli hale getirmenizi sağlar.<br /><br />İşte Ati STP ile birlikte alabileceğiniz modüller: 
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        </div>
        <div className="modul-bolumu-main-container">
        <div className="container card-group disply-blck">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
          Raf-Adres Takibi
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Ürünlerinizin depo içerisindeki fiziksel konumlarını takip ediyoruz. 
          Bu şekilde hangi ürünün nerede ve ne kadar olduğunu anlık bilerek verimlilik artışı ile 
          yer ve zamandan kazanabilirsiniz.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
             Seri-Lot Takibi
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          ​Seri- Lot takibi ürünlerinizin kalitesi ve güvenliğinin izlenmesi ve kontrol edilebilmesine 
          olanak sağlar. Hangi parti ve Lotta ne kadar ürününüz kaldığını rahatlıkla yönetebilirsiniz.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container ">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Depo Hareketleri Modülü</Card.Title>
          <Card.Text className="farkımız-card-text">
          Çeşitli sebeplerle depolar arası transferler söz konusu olduğunda fire ve sayım hizmetleri 
          bu modülümüzde bulunmaktadır.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
        </div>
        <div className="cozumler-haric-main-container">
        <Card className="cozumler-card">
          <div className="container cozumler-card-container">
            <Card.Body  className="cozumler-card-body">
             <Card.Title className="cozumler-card-title">Diğer Çözümlerimizi de Değerlendirin.</Card.Title>
             <Card.Text className="cozumler-card-text">
             Farklı depo seviyelerine özel çözümler sunan Ati Software & Automation olarak 
             sizlere sunduğumuz depo yönetimi çözümleriyle iş süreçlerinizi optimize etmeniz ve 
             verimliliğinizi artırmanız için buradayız.<br />
             <br />
             Eğer Ati STP isimli ürünümüzü incelediyseniz, şimdi sizi diğer ürünlerimizi keşfetmeye 
             davet ediyoruz. Her biri özelleştirilmiş ve farklı seviyelerde operasyon becerisi gerektiren 
             depoların ihtiyaçlarını karşılayacak şekilde tasarlanmıştır.
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        <div className="cozumler-cards-ozet-container">
        <div className="container card-group cards-part-one" style={{ gap: '3rem' }} >
            <Card  className="cozumler-card-border">
               <Card.Img variant="top" src={Img2} />
               <Card.Body>
                  <Card.Title className="cozumler-card-title">Primary Ati WMS</Card.Title>
                  <Card.Text className="cozumler-card-text">
                  Bir depo içerisinde farklı depo sahiplerinin birlikte çalışması, detaylı ürün tarama ve raporları ile Primary Ati WMS Stok Takip programına ek olarak ihtiyaçlarınıza yönelik birçok opsiyon sunuyor.
                    </Card.Text>
                  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <a href="/urunlerimiz/primary-ati-wms"><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                  </div>
                </Card.Body>
            </Card>
            <Card  className="cozumler-card-border">
                <Card.Img variant="top" src={Img3} />
                <Card.Body>
                   <Card.Title className="cozumler-card-title">Professional Ati WMS</Card.Title>
                   <Card.Text className="cozumler-card-text">
                   Primary Ati WMS in avantajlarına ek olarak stoğunuzun ihtiyaca göre depolanmadan çapraz yükleme ile gönderilmesi gibi birçok zaman ve emekten kazandıracak uygulama ile hizmetinizde.
                </Card.Text>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <a href="/urunlerimiz/professional-ati-wms"><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                    </div>
                </Card.Body>
            </Card>
            <Card  className="cozumler-card-border">
                <Card.Img variant="top" src={Img4} />
                <Card.Body>
                <Card.Title className="cozumler-card-title">Ultimate Ati WMS</Card.Title>
                <Card.Text className="cozumler-card-text">
                Kit ve ekipmanlarınızın programımız ile entegre yönetimi, depo içi nakliye yönetimi, rota optimizasyonu, ihtiyaç duyulan entegrasyon ve optimizasyonlar, kişiselleştirilmiş ihtiyaçlar için global firmalara global çözümler.
                </Card.Text>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <a href="/urunlerimiz/ultimate-ati-wms"><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                </div>
                </Card.Body>
            </Card>
        </div>
        </div>
        </div>
        </>
    )
}
export default StokTakipProgramiAtiSTP;