import "./index.scss";
import logom from "../../assets/images/logo-turkuaz6.png";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';


const Header2 = ()=> {
  
  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlHeader2 = () => {
    if (typeof window !== 'undefined') { 
      if (window.scrollY < lastScrollY) { // if scroll down hide the navbar
        setShow(false); 
      } else { // if scroll up show the navbar
        setShow(true);  
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY); 
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlHeader2);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlHeader2);
      };
    }
  }, [lastScrollY]);

  

    return (
      <div className={`header22  ${show && 'hidden-header22'}`}>
       <Navbar expand="lg" className="navbar-style">
      <Container fluid >
        <Navbar.Brand href="/"><div className="style-brand-container"><img alt="logo" src={logom} width={66} height={58} /><h1 className="brand-style">Ati Software &<br />Automation</h1></div></Navbar.Brand>
        <Navbar.Toggle  aria-controls="basic-navbar-nav"><FontAwesomeIcon className="toggle-style" icon={faBars} /></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav"className="collapse-nav" >
          <Nav className="me-auto style-navbar-content rightside-nav">
           <NavDropdown title={<span className="style-nav-item px-5">Ürünlerimiz</span>} id="dropdown-button-drop-down-centered">
              <NavDropdown.Item className="style-nav-item" href="/urunlerimiz/genel-bilgiler">Genel Bilgiler</NavDropdown.Item>
              <NavDropdown.Item className="style-nav-item" href="/urunlerimiz/stok-takip-programı-ati-stp">Stok Takip Programı Ati STP</NavDropdown.Item>
              <NavDropdown.Item className="style-nav-item" href="/urunlerimiz/primary-ati-wms">Primary Ati WMS</NavDropdown.Item>
              <NavDropdown.Item className="style-nav-item" href="/urunlerimiz/professional-ati-wms">Professional Ati WMS</NavDropdown.Item>
              <NavDropdown.Item className="style-nav-item" href="/urunlerimiz/ultimate-ati-wms">Ultimate Ati WMS</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="style-nav-item px-5" href="/entegrasyonlar">Entegrasyonlar</Nav.Link>
            <Nav.Link className="style-nav-item px-5" href="/optimizasyonlar">Optimizasyonlar</Nav.Link>
            <NavDropdown title={<span className="style-nav-item px-5">Hizmetlerimiz</span>} id="dropdown-button-drop-down-centered">
              <NavDropdown.Item className="style-nav-item" href="/hizmetlerimiz">Hizmetlerimiz</NavDropdown.Item>
              <NavDropdown.Item className="style-nav-item" href="/hizmetlerimiz/proje-yonetim-hizmetleri">Proje Yönetim Hizmetleri</NavDropdown.Item>
              <NavDropdown.Item className="style-nav-item" href="/hizmetlerimiz/teknolojik-danismanlik">Teknolojik Danışmanlık Hizmetlerig</NavDropdown.Item>
              <NavDropdown.Item className="style-nav-item" href="/hizmetlerimiz/ozel-yazilim-gelistirme">Özel Yazılım Geliştirme</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/iletisim" className="style-nav-item px-5">İletişim</Nav.Link>
            <NavDropdown title={<span className="style-nav-item px-5">Kurumsal</span>} id="dropdown-button-drop-down-centered">
              <NavDropdown.Item className="style-nav-item" href="/kurumsal/hakkimizda">Hakkımızda</NavDropdown.Item>
              <NavDropdown.Item className="style-nav-item" href="/kurumsal/degerlerimiz">Değerlerimiz</NavDropdown.Item>
              <NavDropdown.Item className="style-nav-item" href="/kurumsal/insan-kaynaklari">İnsan Kaynakları</NavDropdown.Item>
              <NavDropdown.Item className="style-nav-item" href="/kurumsal/kvkk-aydinlatma-metni">KVKK Aydınlatma Metni</NavDropdown.Item>
              <NavDropdown.Item className="style-nav-item" href="/kurumsal/kvkk-acik-riza-metni">KVKK Açık Rıza Metni</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      </div>
     )};
    

     export default Header2;