import "./index.scss";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ImgHizmet1 from "../../../assets/images/hizmet11.jpeg";
import ImgHizmet2 from "../../../assets/images/hizmet12.jpeg";
import ImgHizmet3 from "../../../assets/images/hizmet13.jpeg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochip } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';


const Hizmetlerimiz = () => {

    return(
        <div className="hizmetlerimiz-main-cards-container">
    <Card className="entegrasyonlar-card">
          <div className="container cozumler-card-container">
            <Card.Body  className="cozumler-card-body">
             <Card.Title className="cozumler-card-title">
             <br/><br/>Hizmetlerimiz<br/><br/>
             Yaratıcı Çözümler, Teknolojik İlerleme
            </Card.Title>
            <Card.Text className="optimizeDepo-card-text">
            <span className="bold">Ati Software & Automation,</span> yazılım dünyasında öncü bir firma olarak, 
        müşterilerimize en son teknolojileri kullanarak benzersiz hizmetler sunuyoruz. Müşteri 
        memnuniyetini ve iş verimliliğini her zaman en üst düzeyde tutmayı amaçlıyoruz. İşte 
        sunduğumuz ana hizmetlerimiz:
            </Card.Text>
           </Card.Body>
          </div>
        </Card>
    <div className="container optimizeDepo projeYonetimi">
        <CardGroup className="block-display">
        <Card className="optimizeDepo-text-container">
        <Card.Body>
        <Card.Title className="optimizeDepo-card-title"></Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        <br /><br /><span className="bold">Proje Yönetim Hizmetleri:</span><br /> Proje yönetimi, 
        başarılı bir projenin temel taşıdır. Müşterilerimizin projelerini daha verimli ve etkin bir 
        şekilde yönetmelerine yardımcı olmak için deneyimli bir ekip ile çalışıyoruz. Proje süreçlerini 
        titizlikle planlayarak, bütçeyi koruyarak ve zamanı etkin bir şekilde kullanarak müşterilerimizin 
        hedeflerine ulaşmalarını sağlıyoruz.
        <br /><br /><span className="bold">Teknolojik Danışmanlık Hizmetleri:</span><br />İşletmelerin rekabetçi kalmaları için 
        teknolojik altyapılarını sürekli olarak iyileştirmeleri gerekmektedir. Bu noktada, 
        müşterilerimizin mevcut teknolojik altyapılarını analiz ediyor ve daha iyi bir 
        verimlilik ve etkinlik sağlamak için öneriler sunuyoruz. Teknolojiyi iş stratejileriyle 
        entegre etmelerine yardımcı oluyoruz.<br /><br />
        </Card.Text>
      </Card.Body>
    </Card>
    <Card className="optimizeDepo-img-container">
          <Card.Img  src={ImgHizmet1} />
        </Card>
    </CardGroup>
        </div>
        <div className="container optimizeDepo">
        <CardGroup className="block-display">
        <Card className="optimizeDepo-img-container">
          <Card.Img  src={ImgHizmet2} />
        </Card>
        <Card className="optimizeDepo-text-container">
        <Card.Body>
        <Card.Title className="optimizeDepo-card-title"></Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        <br /><br /><span className="bold">Özel Yazılım Geliştirme Hizmetleri:</span><br /> Her iş farklıdır ve standart yazılım çözümleri her 
        zaman ihtiyaçları karşılamaz. Bu nedenle, müşterilerimize iş ihtiyaçlarına özelleştirilmiş 
        yazılım çözümleri sunuyoruz. Uzman yazılım geliştirme ekibimiz, müşterilerimizin benzersiz 
        gereksinimlerini karşılamak için özel yazılım uygulamaları geliştirir.<br /><br />
        <span className="bold">Eğitim Hizmetleri:</span><br />Yazılımın etkin kullanımı, personelin doğru eğitim almasını 
        gerektirir. Sunduğumuz eğitim hizmetleri ile müşterilerimizin personelini geliştirerek 
        işletmelerinde daha verimli yazılım kullanımını sağlamalarına destek oluyoruz.
        </Card.Text>
      </Card.Body>
    </Card>
    </CardGroup>
        </div>
        <div className="container optimizeDepo">
        <CardGroup className="block-display">
        <Card className="optimizeDepo-text-container">
        <Card.Body>
        <Card.Title className="optimizeDepo-card-title"></Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        <br/><br /><span className="bold">Yardım Masası Hizmeti:</span> <br />Yazılım sorunları iş süreçlerini ciddi şekilde 
        etkileyebilir. Müşterilerimize sunduğumuz yardım masası hizmeti ile yazılım sorunlarına 
        hızlı ve etkin bir şekilde çözüm sunuyoruz. Müşterilerimizin işlerini aksamadan sürdürebilmeleri 
        için her zaman yanındayız.<br /><br />
        Sektördeki uzun yıllara dayanan tecrübemizle, müşterilerimize yüksek kalite standartlarına 
        uygun çözümler sunuyoruz. Başarılı projelerimiz ve memnun müşterilerimiz sayesinde sektörde 
        öncü bir firma olarak kabul ediliyoruz.<br /><br />
        Bize ulaşarak sunduğumuz hizmetlerimiz hakkında daha fazla bilgi alabilirsiniz. İşletmenizin 
        ihtiyaçlarına uygun çözümler sunmaktan mutluluk duyarız.
        </Card.Text>
      </Card.Body>
    </Card>
    <Card className="optimizeDepo-img-container">
          <Card.Img  src={ImgHizmet3} />
        </Card>
    </CardGroup>
        </div>
        <div className="modul-bolumu-main-container">
        <div className="container card-group dsplyBlock">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
          <br/>Proje Yönetim Hizmetleri
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          <br/>Uzman Kadromuzla yazılım geliştirme projeleriniz için danışmanlık hizmeti sunuyoruz.
          </Card.Text>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <a href="/hizmetlerimiz/proje-yonetim-hizmetleri" ><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                </div>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
          Teknolojik Danışmanlık Hizmetleri
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Bilgi Teknolojileri açısından durum verimlilik ve süreç analizi ile birlikte 
          ihtiyacınız olan danışmanlık hizmetini sunuyoruz.
          </Card.Text>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <a href="/hizmetlerimiz/teknolojik-danismanlik"><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                </div>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container ">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title"><br/>Özel Yazılım<br/> Geliştirme</Card.Title>
          <Card.Text className="farkımız-card-text">
          <br/>Karmaşık yazılım süreçleri ile uğraşmayın. En uygun yazılım için uzmanlarımız ile tanışın.<br/>
          </Card.Text>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <a href="/hizmetlerimiz/ozel-yazilim-gelistirme"><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                </div>
        </Card.Body>
      </Card>
      </div>
        </div>
        </div>
    );

}

export default Hizmetlerimiz;