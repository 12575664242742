import "./index.scss";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Img1 from "../../../assets/images/Ati STP.jpeg";
import Img2 from "../../../assets/images/Primary WMS.jpeg";
import Img3 from "../../../assets/images/Professional WMS.jpeg";
import Img4 from "../../../assets/images/Ultimate WMS.jpeg";
import Img5 from "../../../assets/images/devreye-alma.jpeg";

const CozumlerOzet = () => {

    return (
        <>
        <Card className="cozumler-card">
          <div className="container cozumler-card-container">
            <Card.Body  className="cozumler-card-body">
             <Card.Title className="cozumler-card-title">Çözümlerimiz ile deponuz sınırlarını aşsın.</Card.Title>
             <Card.Text className="cozumler-card-text">
             Stok kontrolünden fiyat yönetimine, sevkiyat planlamadan ürün kontrolüne, satın almadan satış yönetimine tüm depo operasyonları için çözüm sağlayan Ati WMS depo büyüklüğünüze uygun opsiyonlarla hizmet veriyor. Şirketiniz büyürken endişelerden uzak şirketinizle büyüyecek olan depo yönetim programımız entegrasyon seçenekleri ile size uçtan uca yönetim imkanı sağlamaktadır.
             </Card.Text>
             <div class="d-grid gap-2 d-md-flex justify-content-md-end">
             <a href="/entegrasyonlar" ><Button size="lg" className="button-style-cozumler-card">Entegrasyon Seçenekleri</Button></a>
             </div>
           </Card.Body>
          </div>
        </Card>
        <div className="cozumler-cards-ozet-container">
        <div className="container card-group cards-part-one" style={{ gap: '3rem' }} >
            <Card  className="cozumler-card-border">
               <Card.Img variant="top" src={Img1} />
               <Card.Body>
                  <Card.Title className="cozumler-card-title">Ati STP</Card.Title>
                  <Card.Text className="cozumler-card-text">
                     Mobil uygulamada da kullanılma seçeneği ile küçük depolarda hızlı hatasız emin operasyonlar yönetmenizi sağlar. Çeşitli modüller ile kapasitesi genişletilebilir, işletmeniz büyürken hızınıza uyum sağlar.
                  </Card.Text>
                  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <a href="/urunlerimiz/stok-takip-programı-ati-stp" ><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                  </div>
                </Card.Body>
            </Card>
            <Card  className="cozumler-card-border">
                <Card.Img variant="top" src={Img2} />
                <Card.Body>
                   <Card.Title className="cozumler-card-title">Primary Ati WMS</Card.Title>
                   <Card.Text className="cozumler-card-text">
                      Bir depo içerisinde farklı depo sahiplerinin birlikte çalışması, detaylı ürün tarama ve raporları ile Primary Ati WMS Stok Takip programına ek olarak ihtiyaçlarınıza yönelik birçok opsiyon sunuyor.
                    </Card.Text>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <a href="/urunlerimiz/primary-ati-wms" ><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                    </div>
                </Card.Body>
            </Card>
            <Card  className="cozumler-card-border">
                <Card.Img variant="top" src={Img3} />
                <Card.Body>
                <Card.Title className="cozumler-card-title">Professional Ati WMS</Card.Title>
                <Card.Text className="cozumler-card-text">
                Primary Ati WMS in avantajlarına ek olarak stoğunuzun ihtiyaca göre depolanmadan çapraz yükleme ile gönderilmesi gibi birçok zaman ve emekten kazandıracak uygulama ile hizmetinizde.
                </Card.Text>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <a href="/urunlerimiz/professional-ati-wms" ><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                </div>
                </Card.Body>
            </Card>
        </div>
        <div className="container card-group cards-part-two" style={{ gap: '3rem' }}>
            <Card  className="cozumler-card-border">
                <Card.Img variant="top" src={Img4} />
                <Card.Body>
                    <Card.Title className="cozumler-card-title">Ultimate Ati WMS</Card.Title>
                    <Card.Text className="cozumler-card-text">
                    Kit ve ekipmanlarınızın programımız ile entegre yönetimi, depo içi nakliye yönetimi, rota optimizasyonu, ihtiyaç duyulan entegrasyon ve optimizasyonlar, kişiselleştirilmiş ihtiyaçlar için global firmalara global çözümler.
                    </Card.Text>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <a href="/urunlerimiz/ultimate-ati-wms" ><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                    </div>
                </Card.Body>
           </Card>
           <Card  className="cozumler-card-border">
                <Card.Img variant="top" src={Img5} />
                <Card.Body>
                    <Card.Title className="cozumler-card-title">Devreye Alma Hizmetleri</Card.Title>
                    <Card.Text className="cozumler-card-text">
                    Ülke genelinde kurulmuş tam otomatik 6 akıllı deponun devreye alma işlemlerinde çözüm ortağı olmaktan gurur duyuyoruz. Bu alandaki tecrübemizle devreye alma projelerinde her türlü yazılım desteğini sunmaya hazırız.
                    </Card.Text>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <a href="/hizmetlerimiz/proje-yonetim-hizmetleri" ><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                    </div>
               </Card.Body>
           </Card>
        </div>
        </div>
        </>
    )
};



export default CozumlerOzet;