import "./index.scss";
import Card from 'react-bootstrap/Card';
import image1 from "../../../assets/images/gnlblg1.jpeg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochip, faRectangleList, faLayerGroup, faTruckRampBox, faHandsHoldingChild, faTruckFast} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Img1 from "../../../assets/images/Ati STP.jpeg";
import Img2 from "../../../assets/images/Primary WMS.jpeg";
import Img3 from "../../../assets/images/Professional WMS.jpeg";

const Ultimate = () => {

    return(
        <>
        <div className="ultimate-ati-wms-main-container">
        <Card className="bg-dark">
        <Card.Img src={image1} alt="Card image" className="card-image gnlblg1"/>
        <Card.ImgOverlay>
        <Card.Title className="card-title-gnlblg">Ultimate Ati WMS<br /><br />
        Baştan Sona Entegre Depo!
        </Card.Title>
        <Card.Text className="card-text-gnlblg">
        Büyük İşletmeler İçin Depo Yönetimi & Entegrasyon Seçenekleri sunan Ultimate Ati WMS
         hızlı yatırım getirisinin yanında depo yönetiminde konfor sağlamaktadır. 
         <br /><br />Çığır açan özelliklerinin yanında eklenebilecek olan modülleri ile bir işletmenin 
         ihtiyaç duyabileceği tüm yazılım ve donanım altyapısını hizmetinize sunmaktadır.
        </Card.Text>
        </Card.ImgOverlay>
        </Card>
        <Card className="ultimate-farkımız-card">
          <div className="container ultimate-farkımız-card-container">
            <Card.Body  className="professional-farkımız-card-body">
             <Card.Title className="professional-farkımız-card-title">Ultimate Ati WMS<br /><br />TEMEL ÖZELLİKLER</Card.Title>
             <Card.Text className="professional-farkımız-card-text">
             Ultimate Ati WMS  büyük işletmeler için depo yönetimini ve stok takibini optimize eden  
             modüler altyapılı güçlü bir yazılımdır. İşletmenizin ihtiyaçları doğrultusunda modüller 
             ekleyerek deponuz için özelleştirilmiş kabiliyeti daha yüksek bir yazılım da elde edebilirsiniz. <br /> 
             <br />İşte Ultimate Ati WMS'in sağladığı temel özellikler: 
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        <div className="farkımız-cards-container">
        <div className="container card-group position-up">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faHandsHoldingChild} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
             Kullanıcı Dostu
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Kullanıcı dostu basit bir arayüz sayesinde işlemlerinizi hatasız ve hızlı yönetmenizi sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
             Teknoloji
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Ultimate Ati WMS maksimum verimlilik için son teknoloji kullanılarak geliştirilmiştir.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container ">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faLayerGroup} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Modüler Altyapı</Card.Title>
          <Card.Text className="farkımız-card-text">
          Sizin için geliştirilmiş Modüller ile size en uygun Ultimate Ati WMS programını birlikte oluşturalım.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up">
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckRampBox} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Mal Kabul</Card.Title>
          <Card.Text className="farkımız-card-text">
          Deponuza giren tüm ürünlerin gerekli bilgileri girerek depoya kabulünü sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card  className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faRectangleList} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Sipariş İşlemleri</Card.Title>
          <Card.Text className="farkımız-card-text">
          Ultimate Ati WMS ile siparişin depodan transferine kadar tüm sürecini rahatça izleyip yönetebilirsiniz.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckFast} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Sevk Yönetimi</Card.Title>
          <Card.Text className="farkımız-card-text">
          Ürünlerinizin sevk aşamalarını ve son kontrollerini rahat bir şekilde yönetin.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faHandsHoldingChild} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
          Deponun Başka Firmalarla<br /> Ortak Kullanımı (Multi-Owner)
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Ortak depo kullanmanıza imkan sağlayan Ultimate Ati WMS, deponuz için yapacağınız yatırım maliyetini 
          bölme imkanı sağlayarak bütçenize uygun çözümü deponuzun ihtiyaçlarını karşılayan WMS 
          algoritmalarıyla hizmetinize sunuyor.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
          Teslim Alma & Yerleştirme <br />(Receiving & Put Away)
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Ultimate Ati WMS ile tüm teslim alma ve depolama işlevlerinizi kolaylaştırabilirsiniz. 
          Ultimate Ati WMS ile size özel hazırlanan benzersiz iş kurallarınız ve ambar akışınıza 
          dayalı olarak, alınan malların nakliye iskelenizden depolama konumunuza hareketini hızlandırır.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container ">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faLayerGroup} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title"><br />Depo Hareketleri<br /> Modülü</Card.Title>
          <Card.Text className="farkımız-card-text">
          <br />
          Çeşitli sebeplerle depolar arası transferler söz konusu olduğunda fire ve sayım hizmetleri bu 
          modülümüzde bulunmaktadır. Eğer depolar arası transfer gibi operasyonlarınız varsa bu modül tam size göre.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up">
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckRampBox} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Raporlama & Analizler</Card.Title>
          <Card.Text className="farkımız-card-text">
          Ultimate Ati WMS sunduğu raporlama hizmetleri ile size depo, ekipman ve personel 
          kapasitenizi ne kadar etkili kullandığınızı gösterir.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card  className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faRectangleList} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Envanter Yönetimi</Card.Title>
          <Card.Text className="farkımız-card-text">
          Ultimate Ati WMS deponuzda envanter segmentasyonu yaparak her segment için kendi iş 
          kurallarına göre depoyu yönetebilmenizi sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckFast} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Raf-Adres Takibi</Card.Title>
          <Card.Text className="farkımız-card-text">
          Ürünlerinizin depo içerisindeki fiziksel konumlarını takip ediyoruz. Bu şekilde hangi ürünün 
          nerede ve ne kadar olduğunu anlık bilerek verimlilik artışı ile yer ve zamandan kazanabilirsiniz.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faHandsHoldingChild} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
          <br />
          Çarpraz Yükleme (Cross Docking)
          </Card.Title>
          <Card.Text className="farkımız-card-text">
        Çapraz sevkiyat, ürünlerin stoğa alınmadan ya da depolara yerleştirilmeden direkt olarak 
        paketli ve kutu içeriği bozulmamış hali ile müşteri ihtiyaçlarına göre kategorilere ve belirli 
        sınıflara ayrılarak buradan müşterilere gönderilmesi işlemine verilen isimdir. Ultimate Ati WMS 
        Cross Docking özelliği taşımaktadır.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
          Dalgalara Göre Toplama (Grouping Orders For Preparation By Wave)
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Ultimate Ati WMS size siparişlerinizi hazırlarken sadece toplanacak ürünlerin yerlerine 
          göre değil, aynı zamanda son müşteri, taşıyıcı türü, yükün hacmi veya boyutu, teslimat 
          süreleri vb. kriterlere göre de ürünleri sevk için toplama imkanı sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container ">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faLayerGroup} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title"><br /><br />Seri-Lot Takibi</Card.Title>
          <Card.Text className="farkımız-card-text"><br /><br />
          Seri- Lot takibi ürünlerinizin kalitesi ve güvenliğinin izlenmesi ve kontrol 
          edilebilmesine olanak sağlar. Hangi parti ve Lotta ne kadar ürününüz kaldığını 
          rahatlıkla yönetebilirsiniz.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faHandsHoldingChild} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
            Ürün Rotasyonu Optimizasyonu
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          <br />FIFO yöntemine de uyumlu algoritmalarımız ile ürün giriş çıkış yönetiminde kayıpları ortadan 
          kaldırıyoruz.Son kullanma tarihleri ve depoya giriş çıkış tarihlerini birlikte değerlendirerek 
          depodaki olası kayıpları engellemiş oluyoruz.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
            Depoda Ihtiyaçları Ongörme (Warehouse Forecasting)
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Her deponun rutini farklı işler. Depolarda ihtiyacı analizler ışığında tahmini belirli bir 
          yerde, belirli zamanda ne kadar envantere ihtiyacınız olduğunu öngörmektir. Sonuç olarak bu 
          öngörü sizin olası senaryolara hazır olabilmenizi sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container ">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faLayerGroup} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Parça Parça Toplama ve Paketleme (Picking & Packing)</Card.Title>
          <Card.Text className="farkımız-card-text">
          Ultimate Ati WMS Picking faaliyetlerini hızlandırmak için kullandığı algoritmalar ile depo 
          içerisinde en kısa yolu çalışan için belirler. Genellikle depoda en fazla zaman alan ve 
          çalışanın emeğine bağlı olan bu aşama Ultimate Ati WMS ile tam zamanında ve doğru Picking 
          sonuçları sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up">
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckRampBox} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">3PL (Third-Party Logistics)</Card.Title>
          <Card.Text className="farkımız-card-text">
          Perakende sipariş karşılamayı otomatikleştirmeye yönelik araçlar ve altyapı sağlayan bu 
          Ati WMS modülü de Ultimate Ati WMS ürünümüzle birlikte sizlerin hizmetinize sunulmaktadır.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card  className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faRectangleList} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">İş Gücü<br /> Yönetimi</Card.Title>
          <Card.Text className="farkımız-card-text">
          Personelin iş tamamlama sürelerini kaydeder ve her biri için tahmini standart süreleri 
          hesaplar. Bu şekilde verimlilik oranlarını takip edebilirsiniz.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckFast} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Depo Önizleme</Card.Title>
          <Card.Text className="farkımız-card-text">
          Deponuzdaki anlık değişimi yansıtan üzerinde işlem yapabileceğiniz depo haritanız 
          Ultimate Ati WMS ile hizmetinizde. Müşteriye özel hazırlanan depo haritası ile tüm envanter göz önünde.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
        </div>
        <Card className="farkımız-card">
          <div className="container farkımız-card-container">
            <Card.Body  className="farkımız-card-body">
             <Card.Title className="farkımız-card-title">Dilediğiniz Modüller ile <br /><br />Ultimate Ati WMS'i Genişletebilirsiniz.</Card.Title>
             <Card.Text className="farkımız-card-text">
             Ultimate Ati WMS'i modüllerle genişletmek, işletmenizin özel ihtiyaçlarını karşılamak
            için harika bir yoldur. Bu modüller, depo yönetimi süreçlerini iyileştirmenizi ve daha
            verimli hale getirmenizi sağlar.<br /><br />İşte Ultimate Ati WMS ile birlikte alabileceğiniz 
            modüller: 
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        </div>
        <div className="modul-bolumu-main-container">
        <div className="container card-group dsplyBlock">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
          Birden Fazla Ürünün Yeni Kit Olarak Depolanması (Kitting)
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Kitting işletmeye daha az popüler ürünlerini popüler olanlarla eşleştirerek satma imkanı 
          sağlarken müşteriye ürün başına daha az maliyet imkanı oluşturur. Ultimate Ati WMS kitting 
          özelliği ile müşteri memnuniyetinizi artırmanın yanı sıra, geliri ve kârı artırmanıza da 
          yardımcı olur.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
          Bulut tabanlı <br />Ultimate Ati WMS
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          İstediğiniz seviyede kullanabileceğiniz depo yönetim yazılımı Ultimate Ati WMS Cloud, 
          deponuz için siber güvenlik açısından verdiği üstün hizmet ile çoklu depo sistemlerinin 
          işlevlerini heryerden yönetmenizi sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
        </div>
        <div className="cozumler-haric-main-container">
        <Card className="cozumler-card">
          <div className="container cozumler-card-container">
            <Card.Body  className="cozumler-card-body">
             <Card.Title className="cozumler-card-title">Diğer Çözümlerimizi de Değerlendirin.</Card.Title>
             <Card.Text className="cozumler-card-text">
             Farklı depo seviyelerine özel çözümler sunan Ati Software & Automation olarak 
             sizlere sunduğumuz depo yönetimi çözümleriyle iş süreçlerinizi optimize etmeniz ve 
             verimliliğinizi artırmanız için buradayız.<br />
             <br />
             Eğer Primary Ati WMS isimli ürünümüzü incelediyseniz, şimdi sizi diğer ürünlerimizi keşfetmeye 
             davet ediyoruz. Her biri özelleştirilmiş ve farklı seviyelerde operasyon becerisi gerektiren 
             depoların ihtiyaçlarını karşılayacak şekilde tasarlanmıştır.
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        <div className="cozumler-cards-ozet-container">
        <div className="container card-group cards-part-one" style={{ gap: '3rem' }} >
            <Card  className="cozumler-card-border">
               <Card.Img variant="top" src={Img1} />
               <Card.Body>
                  <Card.Title className="cozumler-card-title">Ati STP</Card.Title>
                  <Card.Text className="cozumler-card-text">
                  Mobil uygulamada da kullanılma seçeneği ile küçük depolarda hızlı hatasız emin operasyonlar 
                  yönetmenizi sağlar. Çeşitli modüller ile kapasitesi genişletilebilir, işletmeniz büyürken hızınıza 
                  uyum sağlar.
                  </Card.Text>
                  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <a href="/urunlerimiz/stok-takip-programı-ati-stp"><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                  </div>
                </Card.Body>
            </Card>
            <Card  className="cozumler-card-border">
                <Card.Img variant="top" src={Img2} />
                <Card.Body>
                   <Card.Title className="cozumler-card-title">Primary Ati WMS</Card.Title>
                   <Card.Text className="cozumler-card-text">
                   Bir depo içerisinde farklı depo sahiplerinin birlikte çalışması, detaylı ürün tarama ve 
                   raporları ile Primary Ati WMS Stok Takip programına ek olarak ihtiyaçlarınıza yönelik 
                   birçok opsiyon sunuyor.
                </Card.Text>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <a href="/urunlerimiz/primary-ati-wms"><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                    </div>
                </Card.Body>
            </Card>
            <Card  className="cozumler-card-border">
                <Card.Img variant="top" src={Img3} />
                <Card.Body>
                <Card.Title className="cozumler-card-title">Professional Ati WMS</Card.Title>
                <Card.Text className="cozumler-card-text">
                Primary Ati WMS in avantajlarına ek olarak stoğunuzun ihtiyaca göre depolanmadan çapraz 
                yükleme ile gönderilmesi gibi birçok zaman ve emekten kazandıracak uygulama ile 
                hizmetinizde.
                </Card.Text>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <a href="/urunlerimiz/professional-ati-wms"><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                </div>
                </Card.Body>
            </Card>
        </div>
        </div>
        </div>
        </>
    );
}

export default Ultimate;