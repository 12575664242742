import "./index.scss";
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochip} from '@fortawesome/free-solid-svg-icons';



const Degerlerimiz = () => {

    return(
        <>
        <div className="ati-stp-main-container">
        <Card className="farkımız-card">
          <div className="container farkımız-card-container">
            <Card.Body  className="farkımız-card-body">
             <Card.Title className="farkımız-card-title">Değerlerimiz<br/><br/></Card.Title>
             <Card.Text className="farkımız-card-text">
             Bizim için yazılım sadece bir iş değil, aynı zamanda bir tutku ve yaşam tarzıdır. Yazılım 
             firması olarak, müşterilerimize en kaliteli hizmeti sunmak ve dünya çapında etkili yazılım 
             çözümleri oluşturmak için aşağıdaki değerlere bağlıyız: 
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        <div className="farkımız-cards-container">
        <div className="container card-group position-up">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
          Müşteri Odaklılık
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Müşterilerimiz işimizin kalbidir. Müşterilerimize değer verir, onların ihtiyaçlarını anlarız ve 
          işbirliği içinde çalışarak onların hedeflerine ulaşmalarına yardımcı oluruz.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
          Sıradışılık
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Sınırların ötesine geçeriz ve yeni fikirler, teknolojiler ve yaklaşımlar keşfederiz. 
          Müşterilerimize en yenilikçi ve benzersiz yazılım çözümleri sunarız.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container ">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Kalite</Card.Title>
          <Card.Text className="farkımız-card-text">
          Kaliteli yazılım ürünleri ve hizmetler üretmek için yüksek standartlar belirleriz. 
          İşimizde en son teknolojileri kullanır, sürekli olarak gelişmek ve öğrenmek için çaba gösteririz.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up">
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Ekip Çalışması</Card.Title>
          <Card.Text className="farkımız-card-text">
          Birbirimize saygı duyar, işbirliği yapar ve birlikte çalışırız. Başarı, takım çalışmasının sonucudur.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card  className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Sorumluluk</Card.Title>
          <Card.Text className="farkımız-card-text">
          Yaptığımız işin etkisini anlarız ve bununla sorumlu bir şekilde hareket ederiz. 
          Müşterilerimize, çalışanlarımıza, topluma ve çevreye karşı sorumluluğumuzun bilincindeyiz.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Esneklik</Card.Title>
          <Card.Text className="farkımız-card-text">
          Hızlı ve değişken pazar koşullarına uyum sağlamak için esnek ve uyumlu bir yaklaşım 
          benimsiyoruz. Müşterilerimizin ihtiyaçlarına ve değişen taleplerine hızlı bir şekilde yanıt veririz.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
        </div>
        <Card className="farkımız-card">
          <div className="container farkımız-card-container">
            <Card.Body  className="farkımız-card-body">
             <Card.Title className="farkımız-card-title"></Card.Title>
             <Card.Text className="farkımız-card-text">
             Bu değerler, bizim çalışma tarzımızın temelini oluşturur. Müşterilerimiz ve 
             çalışanlarımızın güvenini kazanmak için, bu değerleri günlük iş süreçlerimize 
             entegre ederiz.
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        </div>
        </>
    );
}

export default Degerlerimiz;