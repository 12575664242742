import "./index.scss";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import logom from "../../assets/images/logo-turkuaz6.png"
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import React, { useRef} from "react";
import emailjs from '@emailjs/browser';

const Footer = () => {

    const refForm= useRef();
    const sendEmail= (e) => {
      e.preventDefault();

    emailjs.sendForm('service_mzjjl1k', 'template_bbsnmfe', refForm.current, 'TfEUA4X_900qBB7QH')
    .then(() => {
      alert("Mesajınız başarılı bir şekilde iletilmiştir.")
      window.location.reload(false);
  }, () => {
      alert("Üzgünüz mesajınız iletilemedi, lütfen tekrar deneyin.")
  });
    };
    return (
      <>
      <div className="footer-main-container fluid">
      <CardGroup className="pddng-set">
      <Card className="footer-one-card-genel">
      <a className="footer-link" href="/" ><div className="style-brand-container"><img alt="logo" src={logom} width={66} height={58} /><h1 className="brand-style">Ati Software &<br />Automation</h1></div></a>
        <Card.Body>
          <Card.Text className="footer-left-side-text">
          +90 552 519 3042 <br />
          bilgi@atiotomasyon.net <br />
          Fevzi Çakmak Mah. 10537. Sok No:17<br />
          Karatay KONYA
          </Card.Text>
        </Card.Body>
      </Card>
      <Card className="footer-one-card-genel">
        <Card.Body>
        <ListGroup variant="flush"  className="pddng-set">
        <a className="footer-link" href="/urunlerimiz/genel-bilgiler" ><ListGroup.Item className="footer-link-text">Ürünlerimiz Genel</ListGroup.Item></a>
        <a className="footer-link" href="/urunlerimiz/stok-takip-programı-ati-stp" ><ListGroup.Item className="footer-link-text" >Stok Takip Programı: Ati STP</ListGroup.Item></a>
        <a className="footer-link" href="/urunlerimiz/primary-ati-wms" ><ListGroup.Item className="footer-link-text">Primary Ati WMS</ListGroup.Item></a>
        <a className="footer-link" href="/urunlerimiz/professional-ati-wms" ><ListGroup.Item className="footer-link-text">Professional Ati WMS</ListGroup.Item></a>
        <a className="footer-link" href="/urunlerimiz/ultimate-ati-wms" ><ListGroup.Item className="footer-link-text">Ultimate Ati WMS</ListGroup.Item></a>
        <a className="footer-link" href="/entegrasyonlar" ><ListGroup.Item className="footer-link-text">Entegrasyonlar</ListGroup.Item></a>
        <a className="footer-link" href="/optimizasyonlar" ><ListGroup.Item className="footer-link-text" >Optimizasyonlar</ListGroup.Item></a>
        <a className="footer-link" href="/hizmetlerimiz" ><ListGroup.Item className="footer-link-text">Hizmetlerimiz</ListGroup.Item></a>
        <a className="footer-link" href="/iletisim" ><ListGroup.Item className="footer-link-text">İletişim</ListGroup.Item></a>
        <a className="footer-link" href="/kurumsal/hakkimizda" ><ListGroup.Item className="footer-link-text">Hakkımızda</ListGroup.Item></a>
      </ListGroup>
        </Card.Body>
      </Card>
      <Card  className="footer-one-card-genel">
      <div className="style-message-image-footer"><FontAwesomeIcon icon={faEnvelope} /></div>
        <Card.Body>
          <Card.Title className="style-footer-messageSend">Hemen Başvurun</Card.Title>
          <Card.Text className="footer-right-side-text">
            Ürünlerimiz ile ilgili detaylı bilgi talebi ve sorularınız için formu doldurun.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card className="footer-one-card-genel">
      <Form ref={refForm} onSubmit={sendEmail}>
      <Form.Group className="mb-3"  controlId="formBasicİsim">
        <Form.Label className=" floatingInputCustom footer-form-labels-style">Adınız Soyadınız</Form.Label>
        <Form.Control name="isim" required className="floatingInputCustom footer-form-controls-style" type="text" placeholder="İsminizi belirtiniz." />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicFirmaAdı">
        <Form.Label className="footer-form-labels-style">Firma Adı</Form.Label>
        <Form.Control name="firma_ismi" required className="footer-form-controls-style"  type="text" placeholder="Firma bilgisi giriniz" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="footer-form-labels-style">E-mail</Form.Label>
        <Form.Control name="email" required className="footer-form-controls-style"  type="email" placeholder="E-mail adresinizi belirtiniz." />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea">
        <Form.Label className="footer-form-labels-style">Mesajınız:</Form.Label>
        <Form.Control name="mesaj" required className="footer-form-controls-style"  as="textarea" rows={5} />
      </Form.Group>
      <div className="d-grid gap-2">
      <Button size="lg" type="submit" className="button-style-footer-last-card">
        Gönder
      </Button>
      </div>
      </Form>
    </Card>
    </CardGroup>
      </div>
      </>
    );
};


export default Footer;