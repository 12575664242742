import './App.scss';
import Layout from './components/Layout';
import Home from "./components/Home";
import {Routes, Route} from "react-router-dom";
import "react-bootstrap";
import GenelBilgiler from './components/urunlerimiz/genel-bilgiler';
import StokTakipProgramiAtiSTP from './components/urunlerimiz/Ati-STP';
import Primary from './components/urunlerimiz/Primary';
import Professional from './components/urunlerimiz/Professional';
import Ultimate from './components/urunlerimiz/Ultimate';
import Optimizasyonlar from './components/Optimizasyonlar';
import Entegrasyonlar from './components/Entegrasyonlar';
import ProjeYonetim from './components/Hizmetler/ProjeYonetim';
import Hizmetlerimiz from './components/Hizmetler/Hizmetlerimiz';
import Teknolojik from './components/Hizmetler/TeknolojikDanismanlik';
import OzelYaz from './components/Hizmetler/OzelYazilim';
import Iletisim from './components/Iletisim';
import Hakkimizda from './components/Kurumsal/Hakkimizda';
import Degerlerimiz from './components/Kurumsal/Degerlerimiz';
import InsanKaynaklari from './components/Kurumsal/InsanKaynaklari';
import KVKKAydinlatma from './components/Kurumsal/KVKKAydinlatmaMetni';
import KVKKAcikRiza from './components/Kurumsal/KVKKAcikRizaMetni';

function App() {
            

                
  return (
    <>
    <Routes>
      <Route path="/" element={<Layout />} >
        <Route index element={<Home />} />
        <Route path="/urunlerimiz/genel-bilgiler" element={<GenelBilgiler />} />
        <Route path="/urunlerimiz/stok-takip-programı-ati-stp" element={<StokTakipProgramiAtiSTP />} />
        <Route path="/urunlerimiz/primary-ati-wms" element={<Primary />} />
        <Route path="/urunlerimiz/professional-ati-wms" element={<Professional />} />
        <Route path="/urunlerimiz/ultimate-ati-wms" element={<Ultimate />} />
        <Route path="/entegrasyonlar" element={<Entegrasyonlar />} />
        <Route path="/optimizasyonlar" element={<Optimizasyonlar />} />
        <Route path="/hizmetlerimiz" element={<Hizmetlerimiz />} />
        <Route path="/hizmetlerimiz/proje-yonetim-hizmetleri" element={<ProjeYonetim />} />
        <Route path="/hizmetlerimiz/teknolojik-danismanlik" element={<Teknolojik />} />
        <Route path="/hizmetlerimiz/ozel-yazilim-gelistirme" element={<OzelYaz />} />
        <Route path="/iletisim" element={<Iletisim />} />
        <Route path="/kurumsal/hakkimizda" element={<Hakkimizda />} />
        <Route path="/kurumsal/degerlerimiz" element={<Degerlerimiz />} />
        <Route path="/kurumsal/insan-kaynaklari" element={<InsanKaynaklari />} />
        <Route path="/kurumsal/kvkk-aydinlatma-metni" element={<KVKKAydinlatma />} />
        <Route path="/kurumsal/kvkk-acik-riza-metni" element={<KVKKAcikRiza />} />
      </Route>
    </Routes>
    </>
    
  );
}

export default App;
