import "./index.scss";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ImgOzel1 from "../../../assets/images/ozelyaz1.jpeg";
import ImgOzel2 from "../../../assets/images/ozelyaz2.jpeg";
import ImgOzel3 from "../../../assets/images/ozelyaz3.jpeg";
import ImgOzel4 from "../../../assets/images/teknolojik1.jpeg";




const OzelYaz = () => {

    return(
     <div className="container ozelYaz-main-container">
     <Card className="farkımız-card">
          <div className="container farkımız-card-container">
            <Card.Body  className="farkımız-card-body">
             <Card.Title className="farkımız-card-title"><br/><br/><br/>Özel Yazılım Geliştirme <br/><br/>Hizmetleri</Card.Title>
           </Card.Body>
          </div>
        </Card>
        <div className="optimizasyon-alt-bolum-main-container ">
        <div className="container card-group cards-part-one " style={{ gap: '3rem' }} >
            <Card  className="cozumler-card-border">
               <Card.Img variant="top" src={ImgOzel1} />
               <Card.Body>
                  <Card.Title className="cozumler-card-title"></Card.Title>
                  <Card.Text className="cozumler-card-text">
                  İşletmeler açısından günümüzde rekabet avantajı elde etmek ve iş süreçlerini optimize 
                  etmek için özel yazılımların kullanımı kaçınılmaz hale gelmiştir. Ancak, her işletmenin 
                  ihtiyaçları özeldir ve hazır yazılımların tam olarak işletmenin ihtiyaçlarını 
                  karşılayamayacağı durumlarla karşılaşabilirsiniz. İşte bu noktada özel yazılım 
                  geliştirme hizmetleri sunan bir firmadan yardım alabilirsiniz.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card  className="cozumler-card-border">
                <Card.Body>
                   <Card.Title className="cozumler-card-title"></Card.Title>
                   <Card.Text className="cozumler-card-text">
                   Biz, özel yazılım geliştirme hizmetleri sunan bir firma olarak, müşterilerimize 
                   ihtiyaçlarına uygun özel yazılımların geliştirilmesi konusunda profesyonel destek 
                   sağlıyoruz. Müşterilerimizle yakın bir iş birliği içinde çalışarak, özel yazılımların 
                   gereksinimlerini belirleyerek, tasarlayarak, kodlayarak ve test ederek, müşterilerimize 
                   en uygun ve en verimli yazılımı sunmayı hedefliyoruz.
                </Card.Text>
                </Card.Body>
                <Card.Img variant="top" src={ImgOzel2} />
            </Card>
            <Card  className="cozumler-card-border">
                <Card.Img variant="top" src={ImgOzel3} />
                <Card.Body>
                <Card.Title className="cozumler-card-title"></Card.Title>
                <Card.Text className="cozumler-card-text">
                Sunduğumuz özel yazılım geliştirme hizmetleri arasında, işletmenizin ihtiyaçlarına 
                uygun yazılım tasarımı, geliştirilmesi, kurulumu ve bakımı gibi birçok konu bulunmaktadır. 
                Bu hizmetlerimiz arasında web tabanlı yazılımlar, mobil uygulamalar, masaüstü uygulamaları, 
                bulut bilişim çözümleri, özelleştirilmiş yazılım entegrasyonları ve diğer birçok özel 
                yazılım hizmetleri bulunmaktadır.
                </Card.Text>
                </Card.Body>
            </Card>
        </div>
        </div>
        <div className="container entegreDepo">
        <CardGroup  className="block-display">
        <Card className="entegreDepo-img-container">
          <Card.Img  src={ImgOzel4} />
        </Card>
        <Card className="entegreDepo-text-container">
        <Card.Body>
        <Card.Title className="entegreDepo-card-title"></Card.Title>
        <Card.Text className="entegreDepo-card-text">
        <br/><br/><br/>Ati Software & Automation vasıtasıyla işletmeniz için özel olarak tasarlanmış ve özelleştirilmiş 
        bir yazılımı elde ederek, iş süreçlerinizi optimize edebilir, işletmenizin verimliliğini artırabilir 
        ve müşteri memnuniyetini artırabilirsiniz.<br/><br/><br/> Özel yazılım geliştirme hizmetlerimiz hakkında daha 
        fazla bilgi edinmek için, lütfen bizimle iletişime geçin.
        </Card.Text>

      </Card.Body>
    </Card>
    </CardGroup>
        </div>
     </div>

    );
}
export default OzelYaz;