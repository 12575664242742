import "./index.scss";
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

const Tablo =() => {

    return (
        <div className="container">
      <Table responsive="sm" className="table-general">
        <thead>
          <tr >
            <th className="header-of-the-table class-sbt"></th>
            <th data-label="STOK TAKİP PROGRAMI" className="header-of-the-table class-1">STOK TAKİP<br /> PROGRAMI<br />ATI STP</th>
            <th data-label="PRIMARY Ati WMS" className="header-of-the-table class-2">PRIMARY<br />ATI WMS</th>
            <th data-label="PROFESSIONAL Ati WMS" className="header-of-the-table class-3">PROFESSIONAL<br />ATI WMS</th>
            <th data-label="ULTIMATE Ati WMS" className="header-of-the-table class-4">ULTIMATE<br />ATI WMS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="title-of-the-property class-sbt">Envanter Takibi</td>
            <td data-label="Envanter Takibi"  className="correct-icon class-1"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Envanter Takibi" className="correct-icon class-2"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Envanter Takibi" className="correct-icon class-3"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Envanter Takibi" className="correct-icon class-4"><FontAwesomeIcon icon={faCheck} /></td>
          </tr>
          <tr>
            <td className="title-of-the-property class-sbt">Eksi stok, asgari ve<br /> azami stok miktar<br /> takibi</td>
            <td  data-label="Eksi stok, asgari ve<br /> azami stok miktar<br /> takibi" className="correct-icon class-1"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Eksi stok, asgari ve<br /> azami stok miktar<br /> takibi" className="correct-icon class-2"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Eksi stok, asgari ve<br /> azami stok miktar<br /> takibi" className="correct-icon class-3"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Eksi stok, asgari ve<br /> azami stok miktar<br /> takibi" className="correct-icon class-4"><FontAwesomeIcon icon={faCheck} /></td>
          </tr>
          <tr>
            <td className="title-of-the-property class-sbt">Ayrıntılı ürün<br />tarama özelliği</td>
            <td  data-label="Ayrıntılı ürün<br />tarama özelliği" className="correct-icon class-1"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Ayrıntılı ürün<br />tarama özelliği" className="correct-icon class-2"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Ayrıntılı ürün<br />tarama özelliği" className="correct-icon class-3"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Ayrıntılı ürün<br />tarama özelliği" className="correct-icon class-4"><FontAwesomeIcon icon={faCheck} /></td>
          </tr>
          <tr>
            <td className="title-of-the-property class-sbt">Bir deponun birden<br /> fazla sahibinin olması</td>
            <td  data-label="Bir deponun birden<br /> fazla sahibinin olması" className="wrong-icon class-1"><FontAwesomeIcon icon={faXmark} /></td>
            <td data-label="Bir deponun birden<br /> fazla sahibinin olması" className="correct-icon class-2"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Bir deponun birden<br /> fazla sahibinin olması" className="correct-icon class-3"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Bir deponun birden<br /> fazla sahibinin olması" className="correct-icon class-4"><FontAwesomeIcon icon={faCheck} /></td>
          </tr>
          <tr>
            <td className="title-of-the-property class-sbt">Giriş sevkiyat yönetimi</td>
            <td   data-label="Giriş sevkiyat yönetimi" className="wrong-icon class-1"><FontAwesomeIcon icon={faXmark} /></td>
            <td  data-label="Giriş sevkiyat yönetimi" className="correct-icon class-2"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Giriş sevkiyat yönetimi" className="correct-icon class-3"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Giriş sevkiyat yönetimi" className="correct-icon class-4"><FontAwesomeIcon icon={faCheck} /></td>
          </tr>
          <tr>
            <td className="title-of-the-property class-sbt">Stoğun depolanmadan<br /> çapraz yükleme ile<br /> gönderilmesi <br />(Cross Docking)</td>
            <td  data-label="Stoğun depolanmadan<br /> çapraz yükleme ile<br /> gönderilmesi <br />(Cross Docking)" className="wrong-icon class-1"><FontAwesomeIcon icon={faXmark} /></td>
            <td data-label="Stoğun depolanmadan<br /> çapraz yükleme ile<br /> gönderilmesi <br />(Cross Docking)" className="wrong-icon class-2"><FontAwesomeIcon icon={faXmark} /></td>
            <td data-label="Stoğun depolanmadan<br /> çapraz yükleme ile<br /> gönderilmesi <br />(Cross Docking)" className="correct-icon class-3"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Stoğun depolanmadan<br /> çapraz yükleme ile<br /> gönderilmesi <br />(Cross Docking)" className="correct-icon class-4"><FontAwesomeIcon icon={faCheck} /></td>
          </tr>
          <tr>
            <td className="title-of-the-property class-sbt">Sipariş dalgalarına<br /> göre yönetim</td>
            <td  data-label="Sipariş dalgalarına <br /> göre yönetim" className="wrong-icon class-1"><FontAwesomeIcon icon={faXmark} /></td>
            <td  data-label="Sipariş dalgalarına<br /> göre yönetim" className="wrong-icon class-2"><FontAwesomeIcon icon={faXmark} /></td>
            <td data-label="Sipariş dalgalarına<br /> göre yönetim" className="correct-icon class-3"><FontAwesomeIcon icon={faCheck} /></td>
            <td data-label="Sipariş dalgalarına<br /> göre yönetim" className="correct-icon class-4"><FontAwesomeIcon icon={faCheck} /></td>
          </tr>
          <tr>
            <td className="title-of-the-property class-sbt">Depo içi nakliye <br />rotalarının yönetimi</td>
            <td  data-label="Depo içi nakliye <br />rotalarının yönetimi" className="wrong-icon class-1"><FontAwesomeIcon icon={faXmark} /></td>
            <td data-label="Depo içi nakliye <br />rotalarının yönetimi" className="wrong-icon class-2"><FontAwesomeIcon icon={faXmark} /></td>
            <td data-label="Depo içi nakliye <br />rotalarının yönetimi" className="wrong-icon class-3"><FontAwesomeIcon icon={faXmark} /></td>
            <td data-label="Depo içi nakliye <br />rotalarının yönetimi" className="correct-icon class-4"><FontAwesomeIcon icon={faCheck} /></td>
          </tr>
          <tr>
            <td className="title-of-the-property class-sbt">Kit & Ekipman Yönetimi</td>
            <td  data-label="Kit & Ekipman Yönetimi" className="wrong-icon class-1"><FontAwesomeIcon icon={faXmark} /></td>
            <td  data-label="Kit & Ekipman Yönetimi" className="wrong-icon class-2"><FontAwesomeIcon icon={faXmark} /></td>
            <td data-label="Kit & Ekipman Yönetimi" className="wrong-icon class-3"><FontAwesomeIcon icon={faXmark} /></td>
            <td data-label="Kit & Ekipman Yönetimi" className="correct-icon class-4"><FontAwesomeIcon icon={faCheck} /></td>
          </tr>
        </tbody>
      </Table>
      </div>
    )
};

export default Tablo;