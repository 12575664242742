import "./index.scss";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ImgEntegre from "../../assets/images/entegre.png";



const Entegrasyonlar = () =>{
    return(
        <>
        <div className="entegrasyonlar-main-cards-container">
        <Card className="entegrasyonlar-card">
          <div className="container cozumler-card-container">
            <Card.Body  className="cozumler-card-body">
             <Card.Title className="cozumler-card-title">
             Entegrasyonlar<br /><br />
             Entegre Bir Depo Hayal Değil!<br /><br />
             Ati WMS çözümleri izole değil entegre çalışmak amacıyla geliştirilmiştir.
            </Card.Title>
             <Card.Text className="cozumler-card-text">
             Günümüzde işçilik maliyetlerini ve kaliteyi kontrol edebilmek için Depo Yönetimi 
             Sistemleri (WMS) çözümleri kullanmak artık zorunlu hale gelmiştir. Bütün avantajlarına 
             ek olarak Ati WMS çözümleri;
                <ul>
                <br />
                    <li>Satın almadan etiketlemeye kadar tüm depo süreçlerini otomatize ediyor.</li>
                    <li>En yeni barkod ve RFID gibi teknolojilerle uyum şekilde çalışarak verimliliği artırıyor.</li>
                    <li>El terminalleriyle entegre olan sistem, depolama işlemlerini pratik hale getiriyor.</li>
                    <li>İşletmelerin hedeflerine daha iyi ulaşabilmesi için raporlama araçları sunuyor.</li>
                    <li>Etiket, barkod ve raporların yazdırılması işlemlerini otomatize ediyor.</li>
                    <li>El terminalleri ile sürekli haberleşerek gerekli güncellemeleri sisteme hızla aktarıyor.</li>
                </ul>
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        <div className="container entegreDepo">
        <CardGroup className="block-display">
        <Card className="entegreDepo-img-container">
          <Card.Img  src={ImgEntegre} />
        </Card>
        <Card className="entegreDepo-text-container">
        <Card.Body>
        <Card.Title className="entegreDepo-card-title"></Card.Title>
        <Card.Text className="entegreDepo-card-text">
        Ati WMS çözümleri, yeni nesil teknolojilerle uyumlu 
        şekilde çalışarak işletmeye bütünleşik hizmetler kazandırıyor. 
        Muhasebeden satışa, üretimden bütçelemeye kadar tüm fonksiyonları içeren ERP servisiniz 
        ile Ati WMS ürünleri entegre çalışır. Sürekli güncel kalabilirsiniz. Firmanızda uçtan uca bir 
        yönetim sağlamak sizlere;
          <ul>
            <br/>
            <li>Kapsamlı bir depo yönetim otomasyonu kullanmayan depolarda en az %7 civarında olan fire ve 
                kayıp oranının sıfırlara kadar düşmesini sağlar.
            </li>
            <br/>
            <li>Depo içerisinde personel iş ve işlemlerini yazılım vasıtasıyla kontrol ederek veya personeli devre 
                dışı bırakan otomasyonlar sağlayarak insan kaynaklı hataların yüzde sıfırlara indirilmesini
                 sağlar.
            </li>
            <br/>
            <li>Uçtan uca yönettiğiniz firmanızda depo personelinizin verimliliğini dahi ölçebilen modüller ile hayallerinizi 
                zorlayacak raporlar Ati WMS çözümleri tarafından sağlanmaktadır.
            </li>
            </ul>
        </Card.Text>

      </Card.Body>
    </Card>
    </CardGroup>
        </div>
        </div>
        </>
    );
}
export default Entegrasyonlar;