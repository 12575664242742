import "./index.scss";
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochip, faPeopleGroup, faListCheck, faMagnifyingGlass, faHandsHoldingChild, faHeadphonesSimple} from '@fortawesome/free-solid-svg-icons'

const Farkımız = () => {

    return (
        <>
         <Card className="farkımız-card">
          <div className="container farkımız-card-container">
            <Card.Body  className="farkımız-card-body">
             <Card.Title className="farkımız-card-title">FARKIMIZ</Card.Title>
             <Card.Text className="farkımız-card-text">
             Bizim için yazılım sadece bir iş değil, aynı zamanda bir tutku ve yaşam tarzıdır. Yazılım firması olarak, müşterilerimize en kaliteli hizmeti sunmak ve dünya çapında etkili yazılım çözümleri oluşturmak için çalışıyoruz.
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        <div className="farkımız-cards-container">
        <div className="container card-group position-up">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">Tam Entegrasyon</Card.Title>
          <Card.Text className="farkımız-card-text">
          Bir depo yazılımı için öncelikli önemi olan ERP entegrasyonu başta olmak üzere ihtiyaçlarınız doğrultusunda tam entegre çözümlerimiz ile baştan uca istediğiniz yerden yönetebileceğiniz bir işletme olmak bizimle mümkün.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faPeopleGroup} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Profesyonel Kadro</Card.Title>
          <Card.Text className="farkımız-card-text">
          Sahada devreye alma projelerinden de tanınan kadromuz ile geliştirdiğimiz çözümler sektörün ihtiyaçlarını bire bir karşılamaktadır. Ekibimiz ürün teslimi aşamalarında eğitimleri de büyük bir özveriyle verip gerek görmeniz durumunda dijital danışmanlık da sağlamaktadır.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faListCheck} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Proje Yönetimi</Card.Title>
          <Card.Text className="farkımız-card-text">
          İhtiyacınız olan çözüm detaylı bir kapsam analizinden sonra karşılıklı bir takvime bağlanarak geliştirilir. Test ve gerekli revizyonlar diyalog ile mutabakat sağlanarak yürütülür. Devreye alma aşamasından sonra ise 7/24 destek seçeneği ile sizinleyiz.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up">
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMagnifyingGlass} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Ar-Ge ve Teknoloji</Card.Title>
          <Card.Text className="farkımız-card-text">
          Uzman kadromuz ile her türlü ihtiyacınız profesyonel bir proje yönetim yaklaşımı ile ele alınarak ne kadar karmaşık süreçler olsa da kolaylaştırılacaktır. İhtiyaç duyduğunuz çözümler detaylı analiz sonrasında tecrübemiz ile veri güvenliğiniz sağlanarak size teslim edilecektir.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card  className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faHandsHoldingChild} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Danışmanlık Hizmetleri</Card.Title>
          <Card.Text className="farkımız-card-text">
          Dijital dönüşüm hizmetleri için verdiğimiz danışmanlık faaliyetlerine ek olarak her türlü depo yatırımınız öncesinde yarım saatlik ücretsiz danışmanlık ile beraber size ürünlerimizi tanıtmaktan memnuniyet duyarız.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faHeadphonesSimple} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">7/24 Hızlı Destek</Card.Title>
          <Card.Text className="farkımız-card-text">
          Siz değerli müşterilerimiz için hızlı destek sağlamak bizim en temel önceliğimizdir. Çözüm odaklı yaklaşımımız ile farkı fark edeceksiniz.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
        </div>
        </>
    );
};



export default Farkımız;