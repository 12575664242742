import "./index.scss";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CardGroup from 'react-bootstrap/CardGroup';
import ImgOptimize from "../../../assets/images/optimizeDepo.jpeg";

const OptimizeDepo = () => {
    return (
        <>
        <div className="container optimizeDepo">
        <CardGroup className="block-display">
        <Card className="optimizeDepo-img-container">
          <Card.Img  src={ImgOptimize} />
        </Card>
        <Card className="optimizeDepo-text-container">
        <Card.Body>
        <Card.Title className="optimizeDepo-card-title">Rekabette Kazanabilmek İçin En Etkin Depolar, Optimize Edilmiş Depolardır.</Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        Zamandan, yerden ve kaynaklardan tasarruf etmenin yanı sıra, hataları azaltırken, esnekliği, iletişimi ve yönetimi geliştirmek iyi düşünülmüş depo optimizasyonları ile olur. Depo optimizasyonu çeşitli alanlarda operasyonel faaliyetlerde avantajlar sağlamaktadır.
          <ul>
<li>Stok Devir Hızı Optimizasyonu</li>

<li>Depo Yerleşim Optimizasyonu</li>

<li>Çapraz Yükleme (Cross Docking) Optimizasyonu</li>

<li>Kritik Seviyeler Optimizasyonu</li>

<li>Envanter Segmentasyonu</li>

<li>Optimize Edilmiş İşlemler</li>
</ul>
        </Card.Text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <a href="/optimizasyonlar" ><Button size="lg" className="button-style-optimizeDepo-card">Detaylı Bilgi</Button></a>
        </div>
      </Card.Body>
    </Card>
    </CardGroup>
        </div>
        </>
    );
};



export default OptimizeDepo;