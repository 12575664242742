import "./index.scss";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ImgIk from "../../../assets/images/ik.jpeg";
import ImgIk2 from "../../../assets/images/hakkimizda2.jpeg";



const InsanKaynaklari = () => {

    return (
        <>
        <Card className="entegrasyonlar-card">
          <div className="container cozumler-card-container">
            <Card.Body  className="cozumler-card-body">
             <Card.Title className="cozumler-card-title">
             İnsan Kaynakları<br /><br />
            </Card.Title>
             <Card.Text className="cozumler-card-text">
             Ati Software & Automation, teknolojinin öncüsü olarak her zaman yeni fikirlere açık, 
             yetenekli ve çalışkan insanlar aramaktadır. İnsan kaynakları ekibimiz, işe alım sürecinde 
             şeffaf ve adil bir yaklaşım sergilemektedir. Bizimle çalışarak, profesyonel hedeflerinize 
             ulaşmak için doğru ortamı bulabilirsiniz.
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        <div className="container entegreDepo">
        <CardGroup  className="block-display">
        <Card className="entegreDepo-img-container">
          <Card.Img  src={ImgIk} />
        </Card>
        <Card className="entegreDepo-text-container">
        <Card.Body>
        <Card.Title className="entegreDepo-card-title">Bizimle Çalışmanın Avantajları</Card.Title>
        <Card.Text className="entegreDepo-card-text">
        Firmamız'da çalışmak, teknolojik yeniliklere liderlik etmek ve profesyonel hedeflerinize 
        ulaşmak için eşsiz bir fırsattır. Aşağıda, bizimle çalışmanın bazı avantajları yer almaktadır:
        <br/>
        Gelişim Fırsatları:<br/> Ati Software & Automation, çalışanlarının yeteneklerini geliştirmelerine 
        ve kariyerlerini ilerletmelerine yardımcı olmak için fırsatlar sunar. Yıllık olarak verilen eğitim,
         konferanslara katılım ve mentörlük programları, çalışanlarımızın işlerinde ilerlemelerine 
         yardımcı olur.
         <br/>
        Esnek Çalışma Saatleri:<br/> Firmamız, esnek çalışma saatleri sunarak çalışanlarının iş ve özel 
        yaşam dengesini sağlamalarına olanak tanır.
        <br/>
        İyi İş Ortamı:<br/> Yazılım Firmamız, çalışanlarının mutlu olmasını ve işlerinde başarılı 
        olmalarını sağlamak için gerekli ortamı yaratır.
        </Card.Text>
      </Card.Body>
    </Card>
    </CardGroup>
    <CardGroup  className="block-display">
        <Card className="entegreDepo-text-container">
        <Card.Body>
        <Card.Title className="entegreDepo-card-title"><br/><br/>Başvuru Süreci</Card.Title>
        <Card.Text className="entegreDepo-card-text">
        <br/>Eğer bizimle çalışmak istiyorsanız, lütfen cv hazırlayarak ik@atiotomasyon.net mail 
        adresine iletin. Başvurunuzun ardından, insan kaynakları ekibimiz başvurunuzu inceler 
        ve uygun görürse sizi bir görüşmeye çağırır. Görüşmelerimiz, yeteneklerinizi, tecrübelerinizi 
        ve şirketimize katkılarınızı ölçmek için yapılan değerlendirmelerdir.
        </Card.Text>
      </Card.Body>
    </Card>
    <Card className="entegreDepo-img-container">
          <Card.Img  src={ImgIk2} />
        </Card>
    </CardGroup>
        </div>
        </>
    );
}

export default InsanKaynaklari;