import "./index.scss";
import Card from 'react-bootstrap/Card';
import image1 from "../../../assets/images/gnlblg1.jpeg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochip, faRectangleList, faLayerGroup, faTruckRampBox, faHandsHoldingChild, faTruckFast} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Img1 from "../../../assets/images/Ati STP.jpeg";
import Img3 from "../../../assets/images/Professional WMS.jpeg";
import Img4 from "../../../assets/images/Ultimate WMS.jpeg";

const Primary = () => {

    return(
        <>
        <div className="primary-ati-wms-main-container">
        <Card className="bg-dark">
        <Card.Img src={image1} alt="Card image" className="card-image gnlblg1"/>
        <Card.ImgOverlay>
        <Card.Title className="card-title-gnlblg">Primary Ati WMS<br /><br />
        Birlikte Daha Hızlı Büyüyelim!
        </Card.Title>
        <Card.Text className="card-text-gnlblg">
        Primary Ati WMS Küçük-Orta büyüklükte depo yönetimi süreçleri için ideal çözüm olarak sizlerin 
        hizmetine sunulmaktadır. Unutulmamalıdır ki; depo yönetim sistemi hususunda en çok zorlanan kesim, 
        depo süreçleri küçük- orta büyüklükte kalan işletmelerdir. Depo hareketlerine karar verirken 
        karşılaşacakları zorluklar Orta Büyük ve Büyük işletmelerle aynı olmakla birlikte, yatırım 
        imkanları aynı değildir. <br /><br />Ati Software olarak bu ölçekte işletmelere özel olarak 
        geliştirilmiş en kapsamlı WMS yazılımını sunuyoruz.
        </Card.Text>
        </Card.ImgOverlay>
        </Card>
        <Card className="primary-farkımız-card">
          <div className="container primary-farkımız-card-container">
            <Card.Body  className="primary-farkımız-card-body">
             <Card.Title className="primary-farkımız-card-title">Primary Ati WMS<br /><br />TEMEL ÖZELLİKLER</Card.Title>
             <Card.Text className="primary-farkımız-card-text">
             Primary Ati WMS  küçük-orta büyüklükteki işletmeler için depo yönetimini ve stok takibini optimize eden  
             modüler altyapılı güçlü bir yazılımdır. İşletmenizin ihtiyaçları doğrultusunda modüller 
             ekleyerek deponuz için özelleştirilmiş kabiliyeti daha yüksek bir yazılım da elde edebilirsiniz. <br /> 
             <br />İşte Primary Ati WMS'in sağladığı temel özellikler: 
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        <div className="farkımız-cards-container">
        <div className="container card-group position-up">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faHandsHoldingChild} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
             Kullanıcı Dostu
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Kullanıcı dostu basit bir arayüz sayesinde işlemlerinizi hatasız ve hızlı yönetmenizi sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
             Teknoloji
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Primary Ati WMS maksimum verimlilik için son teknoloji kullanılarak geliştirilmiştir.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container ">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faLayerGroup} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Modüler Altyapı</Card.Title>
          <Card.Text className="farkımız-card-text">
          Sizin için geliştirilmiş Modüller ile size en uygun Primary Ati WMS programını birlikte oluşturalım.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up">
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckRampBox} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Mal Kabul</Card.Title>
          <Card.Text className="farkımız-card-text">
          Deponuza giren tüm ürünlerin gerekli bilgileri girerek depoya kabulünü sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card  className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faRectangleList} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Sipariş İşlemleri</Card.Title>
          <Card.Text className="farkımız-card-text">
          Primary Ati WMS ile siparişin depodan transferine kadar tüm sürecini rahatça izleyip yönetebilirsiniz.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckFast} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Sevk Yönetimi</Card.Title>
          <Card.Text className="farkımız-card-text">
          Ürünlerinizin sevk aşamalarını ve son kontrollerini rahat bir şekilde yönetin.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faHandsHoldingChild} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
          Deponun Başka Firmalarla<br /> Ortak Kullanımı (Multi-Owner)
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Ortak depo kullanmanıza imkan sağlayan Primary Ati WMS, deponuz için yapacağınız yatırım 
          maliyetini paylaşma imkanı sağlayarak bütçenize uygun çözümü deponuzun ihtiyaçlarını karşılayan 
          WMS algoritmalarıyla hizmetinize sunuyor.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
          Teslim Alma & Yerleştirme <br />(Receiving & Put Away)
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Primary Ati WMS ile tüm teslim alma ve depolama işlevlerinizi kolaylaştırabilirsiniz. 
          Primary Ati WMS ile size özel hazırlanan benzersiz iş kurallarınız ve ambar akışınıza dayalı 
          olarak, alınan malların nakliye iskelenizden depolama konumunuza hareketini hızlandırır.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container ">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faLayerGroup} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title"><br />Depo Hareketleri<br /> Modülü</Card.Title>
          <Card.Text className="farkımız-card-text">
          <br />
          Çeşitli sebeplerle depolar arası transferler söz konusu olduğunda fire ve sayım hizmetleri bu 
          modülümüzde bulunmaktadır. Eğer depolar arası transfer gibi operasyonlarınız varsa bu modül tam size göre.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up">
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckRampBox} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Raporlama & Analizler</Card.Title>
          <Card.Text className="farkımız-card-text">
          Primary Ati WMS sunduğu raporlama hizmetleri ile size depo, ekipman ve personel 
          kapasitenizi ne kadar etkili kullandığınızı gösterir.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card  className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faRectangleList} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Envanter Yönetimi</Card.Title>
          <Card.Text className="farkımız-card-text">
          Primary Ati WMS deponuzda envanter segmentasyonu yaparak her segment için kendi iş kurallarına göre 
          depoyu yönetebilmenizi sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckFast} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Raf-Adres Takibi</Card.Title>
          <Card.Text className="farkımız-card-text">
          Ürünlerinizin depo içerisindeki fiziksel konumlarını takip ediyoruz. Bu şekilde hangi ürünün 
          nerede ve ne kadar olduğunu anlık bilerek verimlilik artışı ile yer ve zamandan kazanabilirsiniz.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
        
        </div>
        <Card className="farkımız-card">
          <div className="container farkımız-card-container">
            <Card.Body  className="farkımız-card-body">
             <Card.Title className="farkımız-card-title">Dilediğiniz Modüller ile <br /><br />Primary Ati WMS'i Genişletebilirsiniz.</Card.Title>
             <Card.Text className="farkımız-card-text">
             Primary Ati WMS'i modüllerle genişletmek, işletmenizin özel ihtiyaçlarını karşılamak
            için harika bir yoldur. Bu modüller, depo yönetimi süreçlerini iyileştirmenizi ve daha
            verimli hale getirmenizi sağlar.<br /><br />İşte Primary Ati WMS ile birlikte alabileceğiniz modüller: 
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        </div>
        <div className="modul-bolumu-main-container">
        <div className="container card-group position-up dsplyBlock">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
          Birden Fazla Ürünün Yeni Kit Olarak Depolanması (Kitting)
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Kitting işletmeye daha az popüler ürünlerini popüler olanlarla eşleştirerek satma imkanı 
          sağlarken müşteriye ürün başına daha az maliyet imkanı oluşturur. Primary Ati WMS kitting 
          özelliği ile müşteri memnuniyetinizi artırmanın yanı sıra, geliri ve kârı artırmanıza da 
          yardımcı olur.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
          Parça Parça Toplama ve Paketleme (Picking & Packing)
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Primary Ati WMS Picking faaliyetlerini hızlandırmak için kullandığı algoritmalar ile depo 
          içerisinde en kısa yolu çalışan için belirler. Genellikle depoda en fazla zaman alan ve çalışanın 
          emeğine bağlı olan bu aşama Primary Ati WMS ile tam zamanında ve doğru Picking sonuçları sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container ">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title"><br />Üretim Bölümü (Manufacturing)</Card.Title>
          <Card.Text className="farkımız-card-text">
          Üretim için kullanılabilecek tüm ham madde gruplarını depoladığınız depolar için kullanışlı 
          bir modüldür. Belirli bir hammadde partisinde bir kalite sorunu tespit edildiğinde, 
          Primary Ati WMS yazılımı için bu modül ilgili hammaddeden yapılan tüm bitmiş ürünü belirleyebilir.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up dsplyBlock">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
          3PL (Third-Party Logistics)
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Perakende sipariş karşılamayı otomatikleştirmeye yönelik araçlar ve altyapı 
          sağlayan bu modül de Primary Ati WMS alanlar için bir opsiyon olarak sunulmaktadır.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
          <br />Seri-Lot Takibi
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          ​Seri- Lot takibi ürünlerinizin kalitesi ve güvenliğinin izlenmesi ve kontrol edilebilmesine 
          olanak sağlar. Hangi parti ve Lotta ne kadar ürününüz kaldığını rahatlıkla yönetebilirsiniz.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container ">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Bulut tabanlı Primary Ati WMS</Card.Title>
          <Card.Text className="farkımız-card-text">
          İstediğiniz seviyede kullanabileceğiniz depo yönetim yazılımı Primary Ati WMS Cloud, 
          deponuz için siber güvenlik açısından verdiği üstün hizmet ile çoklu depo sistemlerinin 
          işlevlerini her yerden yönetmenizi sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
        </div>
        <div className="cozumler-haric-main-container">
        <Card className="cozumler-card">
          <div className="container cozumler-card-container">
            <Card.Body  className="cozumler-card-body">
             <Card.Title className="cozumler-card-title">Diğer Çözümlerimizi de Değerlendirin.</Card.Title>
             <Card.Text className="cozumler-card-text">
             Farklı depo seviyelerine özel çözümler sunan Ati Software & Automation olarak 
             sizlere sunduğumuz depo yönetimi çözümleriyle iş süreçlerinizi optimize etmeniz ve 
             verimliliğinizi artırmanız için buradayız.<br />
             <br />
             Eğer Primary Ati WMS isimli ürünümüzü incelediyseniz, şimdi sizi diğer ürünlerimizi keşfetmeye 
             davet ediyoruz. Her biri özelleştirilmiş ve farklı seviyelerde operasyon becerisi gerektiren 
             depoların ihtiyaçlarını karşılayacak şekilde tasarlanmıştır.
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        <div className="cozumler-cards-ozet-container">
        <div className="container card-group cards-part-one" style={{ gap: '3rem' }} >
            <Card  className="cozumler-card-border">
               <Card.Img variant="top" src={Img1} />
               <Card.Body>
                  <Card.Title className="cozumler-card-title">Ati STP</Card.Title>
                  <Card.Text className="cozumler-card-text">
                  Mobil uygulamada da kullanılma seçeneği ile küçük depolarda hızlı hatasız emin operasyonlar 
                  yönetmenizi sağlar. Çeşitli modüller ile kapasitesi genişletilebilir, işletmeniz büyürken hızınıza 
                  uyum sağlar.
                  </Card.Text>
                  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <a href="/urunlerimiz/stok-takip-programı-ati-stp"><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                  </div>
                </Card.Body>
            </Card>
            <Card  className="cozumler-card-border">
                <Card.Img variant="top" src={Img3} />
                <Card.Body>
                   <Card.Title className="cozumler-card-title">Professional Ati WMS</Card.Title>
                   <Card.Text className="cozumler-card-text">
                   Primary Ati WMS in avantajlarına ek olarak stoğunuzun ihtiyaca göre depolanmadan çapraz yükleme ile gönderilmesi gibi birçok zaman ve emekten kazandıracak uygulama ile hizmetinizde.
                </Card.Text>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <a href="/urunlerimiz/professional-ati-wms"><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                    </div>
                </Card.Body>
            </Card>
            <Card  className="cozumler-card-border">
                <Card.Img variant="top" src={Img4} />
                <Card.Body>
                <Card.Title className="cozumler-card-title">Ultimate Ati WMS</Card.Title>
                <Card.Text className="cozumler-card-text">
                Kit ve ekipmanlarınızın programımız ile entegre yönetimi, depo içi nakliye yönetimi, rota optimizasyonu, ihtiyaç duyulan entegrasyon ve optimizasyonlar, kişiselleştirilmiş ihtiyaçlar için global firmalara global çözümler.
                </Card.Text>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <a href="/urunlerimiz/ultimate-ati-wms"><Button size="lg" className="button-style-cozumler-card">Detaylı Bilgi</Button></a>
                </div>
                </Card.Body>
            </Card>
        </div>
        </div>
        </div>
        </>
    );
}

export default Primary;