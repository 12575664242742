import "./index.scss";


const KVKKAcikRiza = () =>{

    return(
        <>
        <div className="container aydinlatma-main">
            <h1 className="aydinlatma-baslik">
            AYDINLATMA METNİ ve ONAY FORMU
            </h1>
            <p className="aydinlatma-text">
            Bu aydınlatma metni 6698 sayılı Kişisel Verilerin Korunması Kanununun 10. Maddesi ile 
            Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uygulanacak Usul ve Esaslar Hakkında Tebliğ
            kapsamında veri sorumlusu sıfatıyla Ati Otomasyon Kimya Arge San. Tic. Ltd. Şti tarafından 
            hazırlanmıştır.
            </p>
            <br />
            <p className="aydinlatma-text">
            Şirketimizce size ait kişisel veriler şirketimize ait kayıtlarda microsoft ortamında 
            tutulacağından bulutta yer alacak olup vereceğiniz açık rıza beyanınızla birlikte 
            kullanılabilecektir. Işbu Verilerin elde edilme amacı size özel fırsatlardan 
            yararlanabilmenizi sağlamak üzere iletişime geçmek ;fırsatları tarafınıza aktarmak olacaktır. 
            Bu kişisel veriler şirket portal üzerinde tutulacak olup pazarlama yetkilisi tarafından 
            görüntülenebilir olacaktır.
            </p>
            <br />
            <p className="aydinlatma-text">
            İşbu kişisel verilerin elde edilme amacıyla 6698 sayılı kanunun 5. Maddesinin 1. 
            Fıkrası gereği ilgili kişinin ‘’açık rızasının alınması ‘’ işleme şartına dayalı olarak 
            otomatik veya otomatik olmayan yollarla işlenecektir.
            </p>
            <br />
            <h3 className="aydinlatma-baslik">
            AÇIK RIZA ONAYI
            </h3>
            <p className="aydinlatma-text">
            6698 Sayılı Kişisel Verilerin Korunması Kanunu kapsamında tarafıma gerekli bilgilendirme 
            yapılmıştır. Bu doğrultuda , işlendiği belirtilen bana ait kişisel verilerimin şirketiniz 
            çalışmalarının değerlendirmesi , kampanya kapsamında işlenmesi amacıyla şirketiniz resmi 
            kayıtlarında tutulmasına onay veriyorum.
            </p>
            <br />
        </div>
        </>

    );
}

export default KVKKAcikRiza;