import "./index.scss";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ImgProje1 from "../../../assets/images/hakkimizda1.jpeg";
import ImgProje2 from "../../../assets/images/hakkimizda2.jpeg";
import ImgProje3 from "../../../assets/images/hakkimizda3.jpg";


const Hakkimizda = () => {

    return(
        <div className="entegrasyonlar-main-cards-container">
        <Card className="entegrasyonlar-card">
          <div className="container cozumler-card-container">
            <Card.Body  className="cozumler-card-body">
             <Card.Title className="cozumler-card-title">
             <br /><br />Hakkımızda<br /><br />
             Dijital İnovasyonun Anahtarı: <br /><br />Ati Software & Automation ile Çalışın.
            </Card.Title>
            <Card.Text className="optimizeDepo-card-text">
        Ati Software & Automation, müşterilerine kaliteli ve yenilikçi yazılım çözümleri sunan öncü bir 
        teknoloji şirketidir. Misyonumuz, müşterilerimize işlerini geliştirmeleri, 
        verimliliklerini artırmaları ve rekabet avantajı elde etmeleri için en iyi yazılım çözümlerini 
        sunmaktır. Vizyonumuz, teknolojik gelişmelere liderlik ederek, yazılım dünyasında 
        sürekli gelişen bir oyuncu olarak kalıcı bir etki yaratmaktır.
        </Card.Text>
           </Card.Body>
          </div>
        </Card>
    <div className="container optimizeDepo projeYonetimi">
        <CardGroup>
        <Card className="optimizeDepo-text-container">
        <Card.Body>
        <Card.Title className="optimizeDepo-card-title"><br /><br />Ürünlerimiz ve Hizmetlerimiz</Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        <br />Ati Software & Automation olarak depo yönetim sistemleri sektöründe sunduğu geniş ürün yelpazesi 
        ve hizmetlerle müşterilerine kaliteli ve yenilikçi çözümler sunmaktadır. Ürünlerimiz detaylı 
        bilgi için <a href="/urunlerimiz/genel-bilgiler" className="link-hakkimizda">tıklayınız.</a><br /><br />
        Hizmetlerimiz arasında yazılım danışmanlığı, yazılım geliştirme ve teknolojik danışmanlık gibi 
        hizmetler yer almaktadır. Sunduğumuz hizmetlerle ilgili detaylı bilgi için 
        <a href="/hizmetlerimiz" className="link-hakkimizda"> tıklayınız.</a>
        </Card.Text>
      </Card.Body>
    </Card>
    <Card className="optimizeDepo-img-container">
          <Card.Img  src={ImgProje1} />
        </Card>
    </CardGroup>
        </div>
        <div className="container optimizeDepo">
        <CardGroup>
        <Card className="optimizeDepo-img-container">
          <Card.Img  src={ImgProje2} />
        </Card>
        <Card className="optimizeDepo-text-container">
        <Card.Body>
        <Card.Title className="optimizeDepo-card-title"><br/>Kurumsal Yapımız</Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        Ati Software & Automation olarak, şirketimizde çalışan her bireyin değerli olduğuna inanıyoruz. 
        Bu nedenle, çalışanlarımızın yaratıcılığı, farklı bakış açıları ve inovasyonu destekliyoruz. 
        Kurumsal yapımızda, şeffaf bir yönetim anlayışı benimsiyoruz ve çalışanlarımızın eğitim, 
        kariyer ve kişisel gelişimleri için destek oluyoruz.
        <br/><br/>
        2018 yılında kurulan firmamız, akıllı depolama sistemleri konusunda uzmanlaşmış bir teknoloji 
        firmasıdır. Kurulduğumuz günden beri, müşterilerimize en yüksek kalitede hizmet sunmayı ve 
        işlerini akıllı depolama sistemleriyle daha verimli hale getirmelerini sağlamayı hedefliyoruz.
        </Card.Text>
      </Card.Body>
    </Card>
    </CardGroup>
        </div>
        <div className="container optimizeDepo">
        <CardGroup>
        <Card className="optimizeDepo-text-container">
        <Card.Body>
        <Card.Title className="optimizeDepo-card-title"><br/>Uzman Ekibimiz ile <br/>yanınızdayız.</Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        Firmamız, alanında uzman mühendislerden ve teknoloji uzmanlarından oluşan bir ekip tarafından 
        yönetilmektedir. Ekibimiz, müşterilerimize en iyi çözümleri sunmak için en son teknolojileri 
        kullanmaktadır. Ayrıca, müşterilerimizin iş süreçlerini anlamak ve ihtiyaçlarını belirlemek için 
        titiz bir çalışma yürütmekteyiz.
        </Card.Text>
        <Card.Title className="optimizeDepo-card-title">Proje Deneyimimiz</Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        Firmamız, bugüne kadar 6 akıllı depolama projesinde görev almıştır. Tüm projelerimizde, 
        müşterilerimize özel çözümler sunarak, işlerini daha verimli hale getirmelerini sağladık. 
        Her projede, ekibimiz sorumluluk sahibi olarak, proje yönetimi, kurulum, entegrasyon ve eğitim 
        süreçlerini yürüttü.
        </Card.Text>
      </Card.Body>
    </Card>
    <Card className="optimizeDepo-img-container">
          <Card.Img  src={ImgProje3} />
        </Card>
    </CardGroup>
        </div>
        </div>
    );
}

export default Hakkimizda;