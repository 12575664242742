import "./index.scss";
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochip, faRectangleList, faLayerGroup, faTruckRampBox, faHandsHoldingChild, faTruckFast} from '@fortawesome/free-solid-svg-icons';
import Image11 from "../../assets/images/increasing2.jpeg";
import Image12 from "../../assets/images/decrease.jpeg";
import Image13 from "../../assets/images/time2.jpeg";

const Optimizasyonlar = () => {

    return(
        <>
        <div className="ati-stp-main-container">
        <Card className="farkımız-card">
          <div className="container farkımız-card-container">
            <Card.Body  className="farkımız-card-body">
             <Card.Title className="farkımız-card-title">Optimizasyonlar<br/><br/>Rekabette Kazanabilmek İçin En Etkin Depolar, <br/><br/>Optimize Edilmiş Depolardır.</Card.Title>
             <Card.Text className="farkımız-card-text">
             Zamandan, yerden ve kaynaklardan tasarruf etmenin yanı sıra, hataları azaltırken, esnekliği, 
             iletişimi ve yönetimi geliştirmek iyi düşünülmüş depo optimizasyonları ile olur. 
             Depo optimizasyonu çeşitli alanlarda operasyonel faaliyetlerde avantajlar sağlamaktadır. 
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        <div className="farkımız-cards-container">
        <div className="container card-group position-up">
        <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faHandsHoldingChild} /></Card.Header>
        <Card.Body >
          <Card.Title className="farkımız-card-title">
          Stok Devir Hızı Optimizasyonu
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Ati WMS ürünleri stokların ne kadar etkin kullanıldığını, hangi ürünün ne kadar popüler 
          olduğunu doğru kararları hızla verebilmeniz için size raporlar.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faMicrochip} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">
          Depo Yerleşim Optimizasyonu
          </Card.Title>
          <Card.Text className="farkımız-card-text">
          Ati WMS size verimliliği artırmak ve yüksek maliyetlerden kurtulmak için etkili 
          bir depo yerleşim optimizasyonu sunmaktadır.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container ">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faLayerGroup} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Çarpraz Yükleme / Cross Docking</Card.Title>
          <Card.Text className="farkımız-card-text">
          Depo maliyetlerini azaltma ve zamandan kazanmanın süper akıllı bir yolu çarpraz yükleme 
          yapabilmektir. Ati WMS depo içine yerleştirme yapmadan gelen ürünü siparişe aktarabilmenizi sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
      <div className="container card-group position-up">
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckRampBox} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Kritik Seviyeler Optimizasyonu</Card.Title>
          <Card.Text className="farkımız-card-text">
          En öncelikli konulardan biri stokların kritiklik seviyelerinin kontrol edilmesidir. 
          Ati WMS ürünleri raporlamalar ile size bu hizmeti sunmaktadır.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card  className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faRectangleList} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Envanter Segmentasyonu</Card.Title>
          <Card.Text className="farkımız-card-text">
          Etkili bir depo yönetimi yapılabilmesi için envanterlerin çeşitli bölümlere ayrılması 
          gerekir. Ati WMS ile her segment kendi özelliklerine göre iş kuralları ile yönetilir.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card   className="farkımız-one-card-container">
        <Card.Header className="farkımız-card-header"><FontAwesomeIcon icon={faTruckFast} /></Card.Header>
        <Card.Body>
          <Card.Title className="farkımız-card-title">Optimize Edilmiş İşlemler</Card.Title>
          <Card.Text className="farkımız-card-text">
          Mevcut makine, ekipman ve işçi kapasitesinin kontrolü verimlilik için son derece 
          önemlidir. Ati WMS her bir personel ve ekipman için verimlilik raporları ile şartları en güzel 
          şekilde yönetmenizi sağlar.
          </Card.Text>
        </Card.Body>
      </Card>
      </div>
        </div>
        <Card className="farkımız-card">
          <div className="container farkımız-card-container">
            <Card.Body  className="farkımız-card-body">
             <Card.Title className="farkımız-card-title">Optimizasyon Ne Sağlar?</Card.Title>
             <Card.Text className="farkımız-card-text">
             Optimizasyon, iş dünyasında vazgeçilmez bir kavram haline gelmiştir ve
             verimliliği artırmak, kaynakları en etkili şekilde kullanmak ve rekabet avantajı elde etmek 
             için kullanılan güçlü bir araçtır. Optimizasyon, karmaşık iş süreçlerini daha verimli hale 
             getirmenin yanı sıra, maliyetleri azaltma, kaliteyi artırma ve hatta daha sürdürülebilir 
             çözümler geliştirme potansiyeli sunar. 
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
        <div className="optimizasyon-alt-bolum-main-container">
        <div className="container card-group cards-part-one" style={{ gap: '3rem' }} >
            <Card  className="cozumler-card-border">
               <Card.Img variant="top" src={Image11} />
               <Card.Body>
                  <Card.Title className="cozumler-card-title"><br/>Yüksek Verim Yüksek Performans</Card.Title>
                  <Card.Text className="cozumler-card-text">
                  Yüksek verimliliğe ulaşabilmek için optimizasyon:
                  <ul>
                    <li>İyi planlanmış operasyonel süreçler sağlar.</li>
                    <li>Mevcut kaynakların etkin kullanılmasını sağlar.</li>
                    <li>Süreçlerin anlık kontrol edilmesi, hızlı kararlar verilebilmesini sağlar.</li>
                    <li>Koordineli iletişim sağlar.</li>
                  </ul>
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card  className="cozumler-card-border">
                <Card.Body>
                   <Card.Title className="cozumler-card-title"><br/>Zamandan Tasarruf</Card.Title>
                   <Card.Text className="cozumler-card-text">
                   Optimizasyonlar planlamada doğru kararların hızlı bir şekilde verilmesini sağlayarak;
                   <ul>
                    <li>Performansı artırır,</li>
                    <li>Depo süreçlerinden kaynaklanan gecikmeleri önler.</li>
                  </ul>
                </Card.Text>
                </Card.Body>
                <Card.Img variant="top" src={Image13} />
            </Card>
            <Card  className="cozumler-card-border">
                <Card.Img variant="top" src={Image12} />
                <Card.Body>
                <Card.Title className="cozumler-card-title"><br/>Düşük Maliyet</Card.Title>
                <Card.Text className="cozumler-card-text">
                Optimizasyonlar Kaynakların etkin ve verimli kullanılması ve 
                operasyonların zamanında yerine getirilmesini sağlayarak;
                   <ul>
                    <li>Oluşabilecek cezaların önüne geçer,</li>
                    <li>Depo kayıplarını önler.</li>
                  </ul>
                </Card.Text>
                </Card.Body>
            </Card>
        </div>
        </div>
        </div>
        </>
    );
    
}

export default Optimizasyonlar;