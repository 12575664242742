import "./index.scss";
import Card from 'react-bootstrap/Card';
import image1 from "../../../assets/images/gnlblg1.jpeg"
import image2 from "../../../assets/images/gnlblg2.jpeg";
import image3 from "../../../assets/images/Professional WMS.jpeg";
import CardGroup from 'react-bootstrap/CardGroup';
import Tablo from "../tablo";
import CozumlerOzet from "../../Home/CozumlerOzet";

const GenelBilgiler =() =>{
 

    return (
        <div className="genel-bilgiler-mail-container">
            <Card className="bg-dark">
            <Card.Img src={image1} alt="Card image" className="card-image gnlblg1"/>
            <Card.ImgOverlay>
            <Card.Title className="card-title-gnlblg">Alt Yapı İhtiyaçlarınızı Görmezden Gelmeyin.<br />
                        Depolama süreçlerindeki kayıplarınızdan kurtulabilirsiniz.
            </Card.Title>
            <Card.Text className="card-text-gnlblg">
            Endüstri 4.0 ve sonrası için hazır olabilmek bütün gelişmeleri hızla avantaja çevirebilecek 
            bir altyapı gerektiriyor. <strong class="blue"> Ati Automation & Software </strong> 
            olarak entegre yazılım ve donanım çözümlerini sizin hizmetinize sunarak baştan uca deponuzu 
            mobil olarak istediğiniz yerden zahmetsizce yönetebilme hizmetini sunuyoruz.
             
             <br />Modern depo yönetim sistemlerini kullanarak sizleri lojistik açıdan geleceğe 
             taşımak hedefindeyiz. Bu noktada her deponun ihtiyaç ve gereksinimleri ile özel olduğunun 
             farkındayız. Her devreye alma işimizi belli bir proje yaklaşımı ile ele alarak %100 müşteri 
             memnuniyeti sağlıyoruz. Proje yaklaşımımızı 
             incelemek için <a className="tıkla" href="/hizmetlerimiz/proje-yonetim-hizmetleri"><strong class="blue">tıklayınız.</strong></a>
            </Card.Text>
            </Card.ImgOverlay>
            </Card>
            <div className="gnlblg-blm-2-container">
        <CardGroup className="block-display">
        <Card className="gnlblg-blm-2">
          <Card.Img  src={image2} />
        </Card>
        <Card className="gnlblg-blm-2">
        <Card.Body>
        <Card.Title className="card-title-gnlblg">WMS : Hatasız Depo Yönetimi</Card.Title>
        <Card.Text className="card-text-gnlblg">
        WMS (Warehouse Management Systems) karmaşık depo süreçleri olan firmaların; stoktaki ürünlerinin miktarlarını ve nerede depolandığını, 3 boyutlu olarak, anlık takip edebilmelerini sağlayan bir sistemdir.

        <br />Basit bir WMS yapısı stok takip programından farklı olarak envanter yönetimini ve konum kontrolünü destekler. Bu seviyede üretilebilen performans verileri genellikle “ depo verimi” ile, yani belirli bir süre içinde depoda ne kadar stok hareket ettiği ile sınırlıdır.

        <br />Orta düzey bir WMS de ise kapasite ve stok seviyelerini analiz edebilir ve farklı faaliyetler için ne kadar zaman ve emek harcandığını takip edebilir. Çalışanların da verimliliğini ölçebilen ve veriler ışığında iyileştirmenin yollarını araştırabileceğiniz bir imkan sağlar.

         <br />Gelişmiş bir WMS yazılımı ise otomatik veya yarı otomatik tüm depo türleri ile uyumlu çalışan, depo ile ilgili faaliyetleri planlarken hammadde stoğu, siparişler, sevkiyatlar vb. tüm süreçlerinizi göz önüne alarak ihtiyaç duyduğunuz analiz, raporlamalar ve öneriler ile optimum sürede mükemmel karar verebilen yazılımdır.
      
        </Card.Text>
      </Card.Body>
    </Card>
    </CardGroup>
    <CardGroup className="block-display">
        <Card className="gnlblg-blm-2">
        <Card.Body>
        <Card.Title className="card-title-gnlblg">Neden WMS?</Card.Title>
        <Card.Text className="card-text-gnlblg">
        Gelişmiş bir WMS Kullanmanın birçok avantajından sadece belli başlı olanları şu şekildedir:
<ul>
<li>Gelişmiş üretkenlik, sipariş hazırlama ve sevkiyatta artırılmış hız</li>

<li>Depolama kapasitesinde %40'a varan artış</li>

<li>Giriş ve çıkış hatalarında %99'a varan azalma</li>

<li>Bir deponun birden fazla sahibi olması veya bir firmanın birden fazla ve farklı dillerde deposunun olması durumlarına hazırlıklı</li>

<li>Gerçek zamanlı envanter</li>

<li>Size özel hazırlanmış raporları kullanın</li>

<li>Herhangi bir ERP ile entegrasyon</li>

<li>Hızlı yatırım getirisi</li>

<li>Haftanın 7 günü 24 saat uzaktan bakım hizmeti (opsiyonel)</li>

Ati Automation & Software olarak ürünlerimiz küçük işletmelerden Global firmaların ihtiyacı olan otomatik depolara kadar geniş bir yelpazeye hitap etmektedir. Entegrasyon seçeneklerimiz stok takip programı hariç tüm ürünlerimizde firmalarımızın ihtiyacına özel olarak sunulmakta ve fiyatlandırılmaktadır. Entegrasyon seçenekleri hakkında detaylı bilgi almak için tıklayınız.
</ul>
        </Card.Text>
      </Card.Body>
    </Card>
    <Card className="gnlblg-blm-2">
          <Card.Img  src={image3} />
        </Card>
    </CardGroup>
    </div>
    <div className="table-title-container"><h2 className="table-title-gnl">Ati WMS Ürün Özellikleri</h2></div>
    <Tablo />
    <CozumlerOzet />

    </div>
    )
};

export default GenelBilgiler;