import "./index.scss";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ImgTek1 from "../../../assets/images/teknolojik1.jpeg";
import ImgTek2 from "../../../assets/images/teknolojik2.jpeg";
import ImgTek3 from "../../../assets/images/teknolojik3.jpeg";

const Teknolojik = () => {
return(

    <>
    <Card className="entegrasyonlar-card">
          <div className="container cozumler-card-container">
            <Card.Body  className="cozumler-card-body">
             <Card.Title className="cozumler-card-title">
             Teknolojik Danışmanlık Hizmetleri<br /><br />
            </Card.Title>
             <Card.Text className="cozumler-card-text">
             İşletmelerin başarılı olması ve rekabet avantajı elde etmeleri için teknolojiyi etkili 
             bir şekilde benimsemeleri ve yönetmeleri kritik bir öneme sahiptir. İşte bu noktada, 
             <spam className="bold">Ati Software & Automation </spam>Teknolojik Danışmanlık Hizmetleri devreye girer. Biz, işletmelerin 
             teknolojiyi stratejik bir avantaj olarak kullanmalarına yardımcı olmak için buradayız. 
             Müşterilerimize yenilikçi çözümler sunmak ve teknolojik dönüşümlerini başarıyla yönlendirmek 
             için uzman bir ekip olarak görev yapıyoruz. Teknolojik Danışmanlık Hizmetleri ile işletmeniz 
             için en uygun teknolojik stratejiyi belirlemenize, en son trendleri takip etmenize ve 
             operasyonlarınızı daha verimli hale getirmenize yardımcı oluyoruz.
             </Card.Text>
           </Card.Body>
          </div>
        </Card>
    <div className="container optimizeDepo projeYonetimi">
        <CardGroup className="block-display">
        <Card className="optimizeDepo-text-container">
        <Card.Body>
        <Card.Title className="optimizeDepo-card-title">İşletmenizi Geleceğe Taşımanın Anahtarı</Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        Günümüz iş dünyası, teknolojiye dayalı hızla değişen bir ortamda faaliyet göstermektedir. 
        İşletmelerin rekabet avantajını sürdürebilmek ve büyümek için teknolojiyi etkin bir şekilde 
        kullanmaları, stratejik bir gereklilik haline gelmiştir. 
        <br/><br/>İşte bu noktada, Ati Teknolojik Danışmanlık Hizmetleri devreye girer.
        Ati Teknolojik Danışmanlık Hizmetleri, işletmelerin teknolojik dönüşümünü başarıyla 
        yönlendirmelerine, yenilikçi çözümlerle büyümelerine ve rekabetçi kalabilmelerine yardımcı olur.
        </Card.Text>
      </Card.Body>
    </Card>
    <Card className="optimizeDepo-img-container">
          <Card.Img  src={ImgTek1} />
        </Card>
    </CardGroup>
        </div>
        <div className="container optimizeDepo">
        <CardGroup className="block-display">
        <Card className="optimizeDepo-img-container">
          <Card.Img  src={ImgTek2} />
        </Card>
        <Card className="optimizeDepo-text-container">
        <Card.Body>
        <Card.Title className="optimizeDepo-card-title">Neden Teknolojik Danışmanlık?</Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        <spam className="bold">Stratejik Yönlendirme:</spam> Teknoloji stratejisinin doğru belirlenmesi işletmenin başarısının temelidir. Teknolojik danışmanlar, işletmelerin ihtiyaçlarına ve hedeflerine uygun bir strateji oluşturur.

        <br/><br/><spam className="bold">Yenilik ve Verimlilik:</spam> Teknoloji, iş süreçlerini optimize ederek verimliliği artırır. Danışmanlar, işletmelerin en son teknolojiyi kullanarak rekabet avantajı elde etmelerine yardımcı olur.

        <br/><br/><spam className="bold">Risk Yönetimi: </spam>Teknolojik değişiklikler riskler içerir. Danışmanlar, olası sorunları tanımlar, önler ve yönetir, böylece işletmeler sorunsuz bir dönüşüm yaşar.

        <br/><br/><spam className="bold">Özelleştirilmiş Çözümler: </spam>Her işletme farklıdır ve bu nedenle her işletmenin teknolojik ihtiyaçları farklıdır. Danışmanlar, işletmenin özel gereksinimlerine uygun çözümler sunar.
        </Card.Text>
      </Card.Body>
    </Card>
    </CardGroup>
        </div>
        <div className="container optimizeDepo">
        <CardGroup className="block-display">
        <Card className="optimizeDepo-text-container">
        <Card.Body>
        <Card.Title className="optimizeDepo-card-title"><br/><br/>Hizmetlerimiz</Card.Title>
        <Card.Text className="optimizeDepo-card-text">
        <br/>Ati Teknolojik danışmanlık hizmetleri, donanım ve yazılım seçimi, bilişim güvenliği, veri analitiği, bulut bilişim yönetimi, dijital dönüşüm stratejileri ve daha fazlasını içerir. Her adımda işletmeye destek sunar ve işletmenin teknolojik potansiyelini en üst düzeye çıkarır.
        </Card.Text>
      </Card.Body>
    </Card>
    <Card className="optimizeDepo-img-container">
          <Card.Img  src={ImgTek3} />
        </Card>
    </CardGroup>
        </div>
    </>
);

}

export default Teknolojik;