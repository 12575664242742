import "./index.scss";
import Carusel from "./Carusel";
import CozumlerOzet from "./CozumlerOzet";
import Farkımız from "./Farkımız";
import EntegreDepo from "./EntegreDepo";
import OptimizeDepo from "./OptimizeDepo";

const Home =() =>{
    return (
        <div className="home-page">
            <Carusel />
            <CozumlerOzet />
            <Farkımız />
            <EntegreDepo />
            <OptimizeDepo />
        </div>
    )
};

export default Home;