import Header1 from "../Header1";
import Header2 from "../Header2";
import "./index.scss";
import {Outlet} from "react-router-dom";
import Footer from "../Footer";

const Layout=() =>{

     return  (
        <div className="App">
           <Header1 />
           <Header2 />
           <div className="page">
             <Outlet />
           </div>
           <Footer />
           


        </div>
      
  )
  
}
export default Layout